import { treatActionsRoutes } from '../utils'

const defaultState = {
    file:""
}

const state = { ...defaultState }



const routes = [
    {
        method: 'POST',
        route: '/pades/sign/{id}',
        action: 'doSignPades',
        responseType: "blob",
        headers: {"Content-Type": "application/pdf"}
    },
    {
        method: 'POST',
        route: '/pades/verify',
        action: 'doPadesVerify',
        headers: {"Content-Type": "multipart/form-data"},
    },
    {
        method: 'POST',
        route: '/cades/sign/{id}',
        action: 'doSignCades',
        headers: {"Content-Type": "application/pdf"},
        responseType:"blob"
    },
    {
        method: 'POST',
        route: '/cades/verify',
        action: 'doCadesVerify',
        headers: {"Content-Type": "multipart/form-data"},
    },
    {
        method: 'POST',
        route: '/util/print',
        action: 'printFriendly',
        headers: {"Content-Type": "application/json"},
        responseType:"blob"
    },
]


const getters = {
    GET_FILE: state => {
        return state.file
    },
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach(key => state[key] = newState[key])
    },
    RESET_STATE(state) {
        Object.keys(state).forEach(key => state[key] = defaultState[key])
    },
}


const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = { body: {}, queryString: {}, routeParams: {} }
    ) => treatActionsRoutes(
        item,
        body,
        queryString,
        routeParams,
        commit,
        defaultState,
        "signature",
        process.env.VUE_APP_API_BACKEND_URL,
        item.headers,
        item.responseType
    )
})

export { state, getters, mutations, actions }