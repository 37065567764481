<template class="d-flex">
  <v-app-bar
    app
    elevate-on-scroll
    id="app-top-bar"
    class="align-self-center justify-center"
  >
    <v-app-bar-nav-icon
      v-if="isLogged && $vuetify.breakpoint.smAndDown && needsMainDrawer"
      @click.stop="toogleMainDrawer()"
    />

    <router-link
      :to="{ name: 'welcome' }"
      class="d-flex ml-5 d-md-none router-link-active"
    >
      <img id="top-bar-logo" :src="getLogo" />
    </router-link>

    <v-spacer />

    <template !isLogged>
      <v-btn
        v-if="$route.name && $route.name !== 'login' && !isLogged"
        outlined
        @click="$router.push({ name: 'login' })"
      >
        {{ $tc('signin') }}
      </v-btn>

      <v-btn
        v-if="
          $route.name &&
          $route.name !== 'join' &&
          $route.name !== 'welcome' &&
          !isLogged
        "
        outlined
        text
        @click="$router.push({ name: 'join' })"
        class="mx-2"
      >
        {{ $tc('sign_up') }}
      </v-btn>
    </template>

    <div class="d-flex mr-sm-8 align-self-end justify-end">
      <div class="mr-md-5">
        <notifications-list
          v-if="isLogged"
          @click="toogleNotificationsDrawer()"
        />
      </div>

      <template>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on" id="abbreviation">
                {{ user.abbreviation }}
              </v-btn>
            </template>
            <v-list class="pl-7 pr-7">
              <v-list-item>
                {{ $tc('top_bar.hello') }}, {{ user.name }}
              </v-list-item>
              <v-list-item
                v-for="(item, index) in getUserMenuItems"
                :key="index"
              >
                <v-list-item>
                  <v-btn icon>
                    <v-icon>{{ item.icon }}</v-icon>
                    {{ item.title }}
                  </v-btn>
                </v-list-item>
              </v-list-item>
              <v-list-item>
                <v-btn plain @click="logout()">
                  <v-icon class="mr-1">mdi-logout</v-icon>
                  {{ $tc('logout') }}
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </div>
  </v-app-bar>
</template>

<script>
import NotificationsList from './NotificationsList'

export default {
  components: {
    NotificationsList,
  },
  computed: {
    getLogo() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return require('@/assets/images/logo/cloud-nuvem-96x96.png')
      }

      if (this.$vuetify.theme.dark) {
        return require('@/assets/images/logo/supercloud_white.png')
      }

      return require('@/assets/images/logo/supercloud_normal.png')
    },
    getUserMenuItems() {
      return [
        // {
        //   title: "Profile",
        //   icon: "mdi-account-circle-outline",
        //   url: "/profile",
        // },
      ]
    },
    userInfo() {
      return this.$store.getters['account/GET_USER_INFO']
    },
    user() {
      const userInfo = this.userInfo
      let lattersArr = userInfo?.name?.trim()?.split(' ')
      let latters = lattersArr?.map((n) => n[0])

      if (lattersArr?.length === 1) {
        latters = lattersArr[0].slice(0, 3).split()
      }

      if (!latters) {
        latters = userInfo?.email
          ?.trim()
          ?.split('@')
          .shift()
          ?.slice(0, 3)
          ?.split()
      }

      if (!latters) {
        latters = ['USR']
      }

      let abbreviation = latters?.map((c) => c.toUpperCase())?.join('')
      return {
        abbreviation,
        ...userInfo,
      }
    },
    isLogged() {
      return this.$store.state.auth.token
    },
    needsMainDrawer() {
      return this.$store.getters['drawers/getPathNeedsDrawer'](this.$route.name)
    },
  },
  methods: {
    toogleMainDrawer() {
      this.$store.dispatch('drawers/toggle', { name: 'mainMenu' })
    },
    toogleNotificationsDrawer() {
      this.$store.dispatch('drawers/toggle', { name: 'notifications' })
    },
    toogleDarkMode(theme) {
      this.$store.commit('ui/SET_THEME', theme)
    },
    logout() {
      this.$store.dispatch('auth/doLogout')
    },
  },
}
</script>

<style scoped lang="scss">
#env-info {
  position: absolute;
  padding: 0;
  top: 0;
  left: 40%;
  width: auto;
  z-index: 99;

  div {
    padding: 0 8px;
  }
}

@media (max-width: 576px) {
  #app-top-bar {
    overflow-x: scroll;
  }
}

#top-bar-logo {
  height: 3.5vh;
  width: auto;
}

#abbreviation {
  font-size: 1.5em;
  font-weight: 700;
  padding: 1.6rem 1rem;
  border-radius: 8%;
}

/* ----------- iPhone XR ----------- */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  #top-bar-logo {
    height: 1.8vh;
    width: auto;
  }
}

/* ----------- iPhone X and others ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  #top-bar-logo {
    height: 2vh;
    width: auto;
  }
}
</style>
