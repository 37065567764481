import { treatActionsRoutes } from '../utils'

const defaultState = {
    file:""
}

const state = { ...defaultState }



const routes = [
    {
        method: 'POST',
        route: '/api/safefile/encrypt/{id}',
        action: 'doEncrypt',
        responseType:"blob",
        responseHeaders: true
    },
    {
        method: 'POST',
        route: '/api/safefile/decrypt',
        action: 'doDecrypt',
        responseType:"blob",
        responseHeaders: true
    },
]


const getters = {
    GET_FILE: state => {
        return state.file
    },
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach(key => state[key] = newState[key])
    },
    RESET_STATE(state) {
        Object.keys(state).forEach(key => state[key] = defaultState[key])
    },
}


const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams, headers } = { body: {}, queryString: {}, routeParams: {}, headers: {} }
    ) => treatActionsRoutes(
        item,
        body,
        queryString,
        routeParams,
        commit,
        defaultState,
        "safefile",
        process.env.VUE_APP_API_BACKEND_URL,
        headers,
        item.responseType,
        item.responseHeaders
    )
})

export { state, getters, mutations, actions }