import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  base: process.env.VUE_APP_BASE_URL || '',
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: (to) => {
        return 'certs-keys'
      },
    },
    {
      path: '/docusign',
      name: 'docusign',
      meta: {
        publicOnly: true,
      },
      component: () => import('./pages/Docusign'),
    },
    {
      path: '/welcome',
      name: 'welcome',
      redirect: (to) => {
        return 'login'
      },
    },
    {
      path: '/control-panel',
      name: 'control-panel',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/DashboardPages/DashboardPage'),
    },
    {
      path: '/certs-keys/:id?',
      name: 'certs-keys',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/ObjectsPages/ObjectsPage'),
    },
    {
      path: '/certs-keys/:id/detail',
      name: 'certs-keys-detail',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/ObjectsPages/CertDetailsPage'),
    },
    {
      path: '/certs-keys-group/:groupId/:id?',
      name: 'certs-keys-group',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/ObjectsPages/ObjectsPage'),
    },
    {
      path: '/signature',
      name: 'signature',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/Signature/Signature'),
    },
    {
      path: '/otp/:id?',
      name: 'otp',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/OTP/OTPPage'),
    },
    {
      path: '/groups',
      name: 'groups',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/Group'),
    },
    // TODO: Reviwe this route to move to child route of groups
    // NOTE: I don't know why this route is not working as child route of groups
    {
      path: '/groups/:id/detail',
      name: 'group-detail',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/Group/GroupDetail'),
    },
    {
      path: '/services',
      name: 'services',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/ServicesPages/ServicesPage'),
    },
    {
      path: '/service/:id',
      name: 'service',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/ServicesPages/ServiceDetailPage'),
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        publicOnly: true,
      },
      component: () => import('./pages/AuthPages/LoginPage/LoginPage'),
    },
    {
      path: '/join',
      name: 'join',
      props: true,
      meta: {
        publicOnly: true,
      },
      component: () => import('./pages/AuthPages/SignUpPage/SignUpPage'),
    },
    {
      path: '/email-sent',
      name: 'email-sent',
      meta: {
        publicOnly: true,
      },
      component: () => import('./pages/AuthPages/EmailSentPage'),
    },
    { path: '/verify_email/:token', redirect: '/verify-email/:token' },
    {
      path: '/verify-email/:token',
      name: 'verify-email',
      meta: {
        publicOnly: true,
      },
      component: () => import('./pages/AuthPages/VerifyEmailPage'),
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: {
        layout: 'blank',
        publicOnly: true,
      },
      component: () =>
        import('./pages/AuthPages/PwdResetPages/ForgotPasswordPage'),
    },
    { path: '/reset_password/:token', redirect: '/reset-password/:token' },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      meta: {
        layout: 'blank',
        publicOnly: true,
      },
      component: () =>
        import('./pages/AuthPages/PwdResetPages/PasswordResetPage'),
    },
    {
      path: '/change-password',
      name: 'change-password',
      meta: {
        layout: 'blank',
      },
      component: () => import('./pages/ChangePasswordPage'),
    },
    {
      path: '/telemetry',
      name: 'telemetry',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/TelemetryPages/TelemetryPage'),
    },
    {
      path: '/certs-report',
      name: 'certs-report',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/CertReportPages/CertReportPage'),
    },
    // TODO: Temporary route for detailed report. Move to certs-report route as child route
    {
      path: '/certs-report/detail',
      name: 'certs-report-detail',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/CertReportPages/CertReportDetailedPage'),
    },
    // TODO: Temporary route for report certificates. These routes will be refactored.
    {
      path: '/certs-report-new',
      name: 'certs-report-new',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/CertReportPagesV2/NewCertReportPage'),
    },
    {
      path: '/certs-report-new/detail',
      name: 'certs-report-detail-new',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/CertReportPages/CertReportDetailedPage'),
    },
    // Finish routes to refactor.
    {
      path: '/lgpd-get-started',
      name: 'lgpd-get-started',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/SVaultPages/LgpdDemoPages/LgpdDemoPage'),
    },
    {
      path: '/vault/:id?',
      name: 'vault',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/SVaultPages/VaultPages/VaultPage'),
    },
    {
      path: '/engine/:id?',
      name: 'engine',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/SVaultPages/EnginePages/EnginePage'),
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('./pages/AccountManagementPages/AccountPage'),
      children: [
        {
          path: '{id}/edit',
          name: 'account-profile-edit',
          meta: {
            layout: 'page',
          },
          component: () =>
            import(
              './pages/AccountManagementPages/SettingsPages/ProfileFormPage'
            ),
        },
        {
          path: 'settings',
          name: 'settings',
          meta: {
            layout: 'page',
          },
          component: () =>
            import('./pages/AccountManagementPages/SettingsPages/SettingsPage'),
        },
        {
          path: 'billing',
          name: 'billing',
          meta: {
            layout: 'page',
          },
          component: () =>
            import('./pages/AccountManagementPages/BillingPages/BillingPage'),
        },
        {
          path: 'api-keys',
          name: 'api-keys',
          meta: {
            layout: 'page',
          },
          component: () =>
            import('./pages/AccountManagementPages/ApiKeysPages/ApiKeysPage'),
        },
        {
          path: 'hsm-lan/:id?',
          name: 'hsm-lan',
          props: true,
          meta: {
            layout: 'page',
          },
          component: () =>
            import('./pages/AccountManagementPages/HsmPages/HsmPage'),
        },
        {
          path: 'hsm-lan/:id/cert-management',
          name: 'cert-management',
          props: true,
          meta: {
            layout: 'page',
          },
          component: () =>
            import(
              './pages/AccountManagementPages/HsmPages/CertManagementPage/CertManagementPage'
            ),
        },
        {
          path: 'hsm-lan/:id/consumer',
          name: 'hsm-consumer',
          props: true,
          meta: {
            layout: 'page',
          },
          component: () =>
            import(
              './pages/AccountManagementPages/HsmPages/HsmConsumerPage.vue'
            ),
        },
        // Temporary route for consumer report. Will be necessary refactor to a new route
        {
          path: 'hsm-lan/:id/consumerv2',
          name: 'hsm-consumerv2',
          props: true,
          meta: {
            layout: 'page',
          },
          component: () =>
            import(
              './pages/AccountManagementPages/HsmPages/NewHsmConsumerPage.vue'
            ),
        },
      ],
    },
    {
      path: '/hsm-lan/firmware-version',
      name: 'firmware-version',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () =>
        import('./pages/AccountManagementPages/HsmPages/FirmwareVersionPage'),
    },
    {
      path: '/file-encryption',
      name: 'file-encryption',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/FileEncryptionPages/FileEncryptionPage'),
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: () => import('./pages/NotFoundPage'),
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/NotAuthorizedPage'),
    },

    // Admin console routers
    {
      path: '/admin/accounts',
      name: 'admin-accounts',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/AdminConsolePages/Accounts'),
    },
    {
      path: '/admin/accounts/:id?/manage',
      name: 'admin-manage-account',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () =>
        import('./pages/AdminConsolePages/Accounts/ManageAccount.vue'),
    },
    {
      path: '/admin/accounts/:id?/hsms',
      name: 'admin-account-hsms',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/AdminConsolePages/HSMs'),
    },
    {
      path: '/admin/services',
      name: 'admin-services',
      meta: {
        layout: 'page',
      },
      component: () => import('./pages/AdminConsolePages/Services'),
    },
    {
      path: '/admin/services/manage/:id?',
      name: 'admin-manage-service',
      props: true,
      meta: {
        layout: 'page',
      },
      component: () =>
        import('./pages/AdminConsolePages/Services/ManageService.vue'),
    },
    {
      path: '/admin/services/status',
      name: 'admin-services-status',
      meta: {
        layout: 'page',
      },
      component: () =>
        import('./pages/AdminConsolePages/Services/StatusPage.vue'),
    },

    {
      path: '*',
      beforeEnter: (to, from, next) => {
        next({ name: 'not-found' })
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const isLogged = store.state.auth.isLogged

  store.commit('ui/SET_LOADING', true)
  store.commit('ui/SET_LAYOUT', to.meta.layout)

  if (!isLogged) {
    if (
      to.matched.some((record) => record.meta.publicOnly || record.meta.public)
    ) {
      store.commit('ui/SET_LOADING', false)
      return next()
    } else {
      store.commit('ui/SET_LAYOUT', 'auth')
      store.commit('ui/SET_LOADING', false)
      return next({ name: 'welcome' })
    }
  } else {
    if (to.matched.some((record) => record.meta.publicOnly)) {
      store.commit('ui/SET_LAYOUT', 'page')
      store.commit('ui/SET_LOADING', false)
      return next({ name: 'home' })
    } else {
      store.commit('ui/SET_LOADING', false)
      return next()
    }
  }
})

export default router
