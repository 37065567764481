<template>
  <div class="text-center">
    <v-menu offset-y open left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            id="notification_btn"
            icon
            v-on="on"
            v-bind="attrs"
        >
          <v-badge :value="count" overlap color="red" :content="count">
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list id="notifications-list-items">
        <div v-if="count">
          <v-list-item
              v-for="(item, index) in notifications"
              :key="item.date?.toString() || index"
              :three-line="item.date && true"
          >
            <v-list-item-content v-if="item.date">
              <div class="col-12 n-date" v-html="item.date"></div>
              <div
                v-for="(not, idx) in item.notifications"
                :key="idx.toString()+item.date"
                class="col-12"
              >
                <v-list-item-title v-html="not.title"></v-list-item-title>
                <v-list-item-subtitle v-html="not.message"></v-list-item-subtitle>
              </div>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-subtitle v-html="item.message"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-subtitle>{{ $tc("user_no_notifications") }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row id="notifications-footer" v-if="false">
        <v-col cols="6" class="d-flex justify-start">
          <v-btn
              text
              @click="clearNotifications"
              color="white"
          >
            {{ $tc("clear_notifications") }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn
            text
            @click="markAsRead"
            color="dinamo"
          >
            {{ $tc("mark_notifications_as_read") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-menu>
  </div>
</template>

<script>
export default {
  created() {
    this.loadNotifications()
  },
  computed: {
    notifications: {
      get() {
        // TODO: Need improvement un object in backend with this data
        /*
        {
          date: string - date of notification
          notifications: {
            id: number - id of notification
            title: string - title of notification
            message: string - message of notification
          }
        }
        */
        return this.$store.state.account.notifications;
      },
    },
    count() {
      if (this.notifications === null) {
        return 0
      } else {
        return this.notifications
            .map(i => {
              if (i.notifications) {
                return i.notifications.length
              } else {
                return 1
              }
            })
            .reduce((a, b) => a + b, 0)
      }
    },
  },
  methods: {
    loadNotifications() {
      this.$store.dispatch('account/doGetNotificationList')
    },
    clearNotifications() {
      alert('Not implemented yet')
    },
    markAsRead() {
      alert('Not implemented yet')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

  div[role="menu"]:not(#notifications-list-items) {
    max-height: 50vh;

    &:has(#notifications-footer) {
      min-height: 12.3vh;
    }
  }

  #notifications-list-items {
    max-width: 420px;

    .v-list-item {
      border-bottom: 1px solid var(--v-secondary-lighten1);

      &:last-child {
        border-bottom: none;
      }
    }

    .n-date {
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 0.8rem;
      color: $primary-color;
      text-transform: uppercase;
    }

    .v-list-item__title {
      font-family: $heading-font-family;
      font-weight: bold;
      font-size: 1rem;
    }

    .v-list-item__subtitle {
      font-size: 1rem;
      margin: 5px auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inherit;
    }

    &.v-list-item__title, .v-list-item__subtitle {
      margin: 5px auto;
    }

  }

  #notifications-footer {
    background-color: #323F41;
    height: 100%;

    .v-btn {
      text-decoration: underline;
      text-underline-position: under;
      text-transform: inherit;
    }
  }

</style>
