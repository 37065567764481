import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.yml$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale:
    localStorage.getItem('locale') ||
    process.env.VUE_APP_I18N_LOCALE ||
    'pt-BR',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-BR',
  messages: loadLocaleMessages(),
  dateTimeFormats: {
    'en-US': {
      dateTimeFormat: 'MM/DD/YYYY hh:mm a',
      dateFormat: 'MM/DD/YYYY',
      timeFormat: 'hh:mm a',
      date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      minute: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      second: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    },
    'pt-BR': {
      dateTimeFormat: 'DD/MM/YYYY HH:mm',
      dateFormat: 'DD/MM/YYYY',
      timeFormat: 'HH:mm',
      date: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      minute: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
      second: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
    }
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'BRL'
      }
    },
    'pt-BR': {
      currency: {
        style: 'currency',
        currency: 'BRL',
      }
    }
  },
})

// List with timezones avaliables
VueI18n.prototype.timezones = {
  'pt-BR': 'America/Sao_Paulo',
  'en-US': 'America/New_York',
};

// Return the timezone for active locale
VueI18n.prototype.getTimezone = function (locale) {
  if (!locale) {
    locale = this.locale;
  }
  return this.timezones[locale];
}

VueI18n.prototype.getDateTimeFormat = function (locale) {
    if (!locale) {
        locale = this.locale;
    }
    return this.dateTimeFormats[locale].dateTimeFormat;
}

VueI18n.prototype.formatDateTime = function (date, locale) {
    return this._vm.$moment(date).format(this.getDateTimeFormat(locale));
}

VueI18n.prototype.getDateFormat = function (locale) {
    if (!locale) {
        locale = this.locale;
    }
    return this.dateTimeFormats[locale].dateFormat;
}

VueI18n.prototype.formatDate = function (date, locale) {
    return this._vm.$moment(date).format(this.getDateFormat(locale));
}

VueI18n.prototype.getTimeFormat = function (locale) {
    if (!locale) {
        locale = this.locale;
    }
    return this.dateTimeFormats[locale].timeFormat;
}

VueI18n.prototype.formatTime = function (date, locale) {
    return this._vm.$moment(date).format(this.getTimeFormat(locale));
}