import axios from 'axios'
import store from '@/store'
import customExceptions from './customExceptions'

export default (
  baseURL = process.env.VUE_APP_PORTAL_BACKEND_URL,
  method,
  route,
  body,
  params,
  routeParams,
  headers = null,
  responseType = null
) => {
  const api = axios.create({
    baseURL,
    timeout: 5000,
  })

  const sleep = (seconds) =>
    new Promise((func) => setTimeout(func, seconds * 1000))

  api.interceptors.request.use(
    async (resp) => {
      while (store.getters['account/GET_IS_REFRESHING_TOKEN']) await sleep(1)

      const acceptLanguage =
        store.state.locale.locale ||
        localStorage.getItem('locale') ||
        store.getters['ui/GET_BROWSER_LANGUAGE'] ||
        'en-US'

      if (resp.url.match(/cades|xades|pades/g)?.length > 0) {
        resp.timeout = 600000 // 10 minutes
      }

      return {
        ...resp,
        headers: {
          ...resp.headers,
          'Accept-Language': acceptLanguage,
          Authorization: `Bearer ${store.getters['auth/GET_TOKENS'].token}`,
        },
      }
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  api.interceptors.response.use(
    (resp) => resp,
    async (err) => {
      const { response, crossDomain } = err
      const statusToTokenRefresh = [401, 403]
      const messagesToTokenRefresh = ['Token expired', 'Invalid token']

      if (crossDomain) return { detail: 'server_unreachable' }
      const isNotLoginURL = response?.config?.url !== '/account/login'

      if (
        isNotLoginURL &&
        (statusToTokenRefresh.includes(response?.status) ||
          messagesToTokenRefresh.includes(response?.data?.detail))
      ) {
        if (store.getters['account/GET_IS_REFRESHING_TOKEN']) {
          await sleep(1)
          return axios.request({
            ...response.config,
            headers: {
              ...response.config.headers,
              Authorization: `Bearer ${store.getters['auth/GET_TOKENS'].token}`,
            },
          })
        } else {
          store.commit('account/SET_IS_REFRESHING_TOKEN')
          return axios
            .post(
              `${process.env.VUE_APP_PORTAL_BACKEND_URL}/account/refresh`,
              undefined,
              {
                headers: {
                  Authorization: `Bearer ${store.getters['auth/GET_TOKENS'].refresh_token}`,
                },
              }
            )
            .then(({ data }) => {
              store.commit('auth/SET_STATE', {
                token: `${data.token}`,
                accountId: data.id,
                refresh_token: `${data.refresh_token}`,
              })
              sessionStorage.setItem('token', data.token)
              sessionStorage.setItem('refresh_token', data.refresh_token)
              return axios.request({
                ...response.config,
                headers: {
                  ...response.config.headers,
                  Authorization: `Bearer ${data.token}`,
                },
              })
            })
            .catch((err) => {
              store.dispatch(
                'error/showErrorNotification',
                err.response?.body || err.response?.data || err.toString()
              )
              store.dispatch('auth/doLogout')
            })
            .finally(() => store.commit('account/SET_IS_REFRESHING_TOKEN'))
        }
      } else if (
        isNotLoginURL &&
        statusToTokenRefresh.includes(response?.data?.status) &&
        !response._retry
      ) {
        store.commit('account/SET_IS_REFRESHING_TOKEN')
        return axios
          .post(
            `${process.env.VUE_APP_PORTAL_BACKEND_URL}/account/refresh`,
            undefined,
            {
              headers: {
                Authorization: `Bearer ${store.getters['auth/GET_TOKENS'].refresh_token}`,
              },
            }
          )
          .then(({ data }) => {
            store.commit('auth/SET_STATE', {
              token: `${data.token}`,
              accountId: data.id,
              refresh_token: `${data.refresh_token}`,
            })
            sessionStorage.setItem('token', data.token)
            sessionStorage.setItem('refresh_token', data.refresh_token)
            response._retry = true
            return axios.request({
              ...response.config,
              headers: {
                ...response.config.headers,
                Authorization: `Bearer ${data.token}`,
              },
            })
          })
          .catch((err) => {
            store.dispatch(
              'error/showErrorNotification',
              err.response?.body || err.response?.data || err.toString()
            )
            this.$store.dispatch('auth/doLogout')
          })
          .finally(() => store.commit('account/SET_IS_REFRESHING_TOKEN'))
      }
      while (store.getters['account/GET_IS_REFRESHING_TOKEN']) await sleep(0.2)
      err = customExceptions(err)
      return Promise.reject(err)
    }
  )

  return api
    .request({
      headers,
      responseType,
      method: method ? method : 'GET',
      url: route.replace(
        /{\w+}/g,
        (routeParam) => routeParams[routeParam.slice(1, routeParam.length - 1)]
      ),
      data: ['PUT', 'POST', 'DELETE', 'PATCH'].includes(method)
        ? body
        : undefined,
      params,
    })
    .catch(async (err) => {
      if (responseType === 'blob') {
        const error = JSON.parse(await err.response.data.text())
        store.dispatch('error/showErrorNotification', error, {
          root: true,
        })
      } else {
        if (err.response?.body) {
          store.dispatch('error/showErrorNotification', err.response.body, {
            root: true,
          })
        } else if (err.response?.data) {
          if (err.response.data.detail) {
            store.dispatch(
              'error/showErrorNotification',
              err.response.data.detail,
              {
                root: true,
              }
            )
          } else {
            store.dispatch('error/showErrorNotification', err.response.data, {
              root: true,
            })
          }
        }
      }
      err = customExceptions(err)
      return Promise.reject(err)
    })
}
