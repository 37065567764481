import { treatActionsRoutes } from '../utils'

const defaultState = {
  occurrenceTypes: {},
  availableOccurTypes: Array(40).fill(false),
}

const state = { ...defaultState }

const routes = [
  {
    method: 'GET',
    route: '/contract/type/{occurTypes}',
    action: 'isOccurHired',
    baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
  },
  {
    state: 'occurrenceTypes',
    method: 'GET',
    route: '/api/data/occurrencetype',
    action: 'doGetOccurrenceTypes',
    baseURL: process.env.VUE_APP_API_BACKEND_URL,
  },
  {
    method: 'GET',
    route: '/contract/type/account',
    action: 'getHiredOccurrences',
    baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
  },
]

const getters = {
  GET_SERVICE_ID: (state, getters, rootState) => (occurType) => {
    const service = rootState.offers.offersList.find((offer) => {
      for (const item of offer.items) {
        if (item.type_id === occurType) return true
      }
      return false
    })
    return service ? service.id : undefined
  },
}

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]))
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
  },
}

const actions = {
  doResetState({ commit }) {
    commit('RESET_STATE')
  },
}

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      'occurrences',
      item.baseURL
    )
})
export { state, getters, mutations, actions }
