import axios from '../../services/axios'

const defaultRes = { data: {}, headers: {} }

export const treatActionsRoutes = (
    item,
    body,
    queryString,
    routeParams,
    commit,
    defaultState,
    module,
    baseUrl,
    headers = null,
    responseType = null,
    responseHeaders = false
) =>
    axios(
        baseUrl,
        item.method,
        item.route,
        body,
        queryString,
        routeParams,
        headers,
        responseType
    ).then((axsObj) => {
        const { data, headers } = { ...defaultRes, ...axsObj }
        if (item.state) {
            let newState = {}
            if (Array.isArray(item.state))
                item.state.forEach(
                    (itemState) => (newState[itemState] = data[itemState])
                )
            else if (item.state === '*')
                Object.keys(defaultState).forEach((key) => {
                    if (data[key] !== undefined) newState[key] = data[key]
                })
            else newState[item.state] = data

            commit(`${module}/SET_STATE`, newState, { root: true })
        }
        return responseHeaders ? { data, headers } : data
    })
