/* eslint-disable */
import i18n from "../../plugins/i18n.plugin";
import { dateSort } from "@/plugins/globals.plugin";
import { treatActionsRoutes } from "../utils";
import moment from "moment";

const defaultState = {
  keyTypeOptions: [],
  keysList: [],
  certList: [],
  resourceList: [],
  chainList: [],
  trackerTypeOptions: [],
};

const state = { ...defaultState };

const routes = [
  {
    method: "POST",
    route: "/api/key/generate",
    action: "doCreateKey",
  },
  {
    state: "keysList",
    method: "GET",
    route: "/api/key",
    action: "doGetKeysList",
  },
  {
    method: "DELETE",
    route: "/api/key/{id}",
    action: "doDeleteKey",
  },
  {
    method: "GET",
    route: "/api/key/{id}",
    action: "doGetKey",
  },
  {
    method: "PUT",
    route: "/api/key/{id}",
    action: "doUpdateKey",
  },
  // CSR
  {
    method: "POST",
    responseRoot: "file",
    route: "/api/key/{id}/csr",
    action: "doGetCSR",
  },
  // IMPORT
  {
    method: "POST",
    route: "/api/key/import",
    action: "doImportKey",
  },
  // OPTIONS
  {
    state: "keyTypeOptions",
    method: "GET",
    route: "/api/data/keytype",
    action: "doGetKeyOptions",
  },
  {
    state: "keyTypeOptions",
    method: "GET",
    route: "/api/data/keytype/symmetric",
    action: "doGetSymmetricKeyOptions",
  },
  // CERTIFICATES
  {
    method: "POST",
    route: "/api/certificate/import",
    action: "doImportCert",
  },
  {
    method: "GET",
    route: "/api/certificate/{id}/export",
    action: "doExportCert",
  },
  {
    state: "resourceList",
    method: "GET",
    route: "/api/resource",
    action: "doGetResourceList",
  },
  {
    state: "certList",
    method: "GET",
    route: "/api/certificate",
    action: "doGetCertList",
  },
  {
    method: "DELETE",
    route: "/api/certificate/{id}",
    action: "doDeleteCert",
  },
  {
    method: "GET",
    route: "/api/certificate/{id}",
    action: "doGetCert",
  },
  {
    method: "PUT",
    route: "/api/certificate/{id}",
    action: "doUpdateCert",
  },
  {
    method: "POST",
    route: "/api/certificate/{certId}/block/{hsmId}",
    action: "doBlockCertInHsm",
  },
  {
    method: "DELETE",
    route: "/api/certificate/{certId}/unblock/{hsmId}",
    action: "doUnblockCert",
  },
  // CHAIN
  {
    method: "POST",
    route: "/api/chain/import",
    action: "doImportChain",
  },
  {
    state: "chainList",
    stateDefault: [],
    method: "GET",
    route: "/api/chain",
    action: "doGetChainList",
  },
  {
    method: "DELETE",
    route: "/api/chain/{id}",
    action: "doDeleteChain",
  },
  {
    method: "GET",
    route: "/api/chain/{id}",
    action: "doGetChain",
  },
  {
    method: "PUT",
    route: "/api/chain/{id}",
    action: "doUpdateChain",
  },
  {
    method: "GET",
    route: "/api/chain/{id}/export",
    action: "doExportChain",
  },
  // TRACKERS
  {
    method: "POST",
    route: "/api/track/{id}",
    action: "doTrackCert",
  },
  {
    method: "DELETE",
    route: "/api/track/{id}",
    action: "doDeleteTrackerFromCert",
  },
  {
    state: "trackerTypeOptions",
    method: "GET",
    route: "/api/data/typetracker",
    action: "doGetTrackerOptions",
  },

  {
    method: "GET",
    route: "/api/certificate/{id}/block",
    action: "doGetHsmsForCertBlock",
  },
];

const getters = {
  GET_TRACKING_CHANNEL_OPTIONS: (state) => {
    let options = [];
    let optionsIcons = {
      watracker: "mdi-whatsapp",
      smstracker: "sms",
      emailtracker: "email",
    };
    Object.entries(state.trackerTypeOptions).forEach(([key]) => {
      // TODO: enable whatsapp again
      if (key === "watracker") return;
      options.push({
        title: i18n.t(key),
        icon: optionsIcons[key],
        value: key,
        id: key,
      });
    });
    return options;
  },
  CHECK_IF_RESOURCE_EXISTS:
    (state) =>
    ({ resourceType, id }) => {
      let list = [];
      if (resourceType === "key") {
        list = state.keysList;
      } else if (resourceType === "x509") {
        list = state.certList;
      }
      return list.find((item) => item.id === id);
    },
  GET_KEY_TYPE_NAME: (state) => (type) => {
    if (!Object.prototype.hasOwnProperty.call(state.keyTypeOptions, type)) {
      return type;
    }
    return state.keyTypeOptions[type];
  },
  GET_KEY_INFO: (state, getters) => (keyDetail) => {
    const info = {
      id: keyDetail.id,
      label: keyDetail.label,
      icon: "vpn_key",
      type: keyDetail.type,
      aliases: keyDetail.aliases,
    };
    if (keyDetail.code) {
      info.family = {
        text: getters.GET_KEY_TYPE_NAME(keyDetail.code),
        value: keyDetail.code,
      };
    }
    if (keyDetail.created) {
      info.created = Date.parse(keyDetail.created);
    }
    if (keyDetail.cert) {
      info.linkedResource = keyDetail.cert;
    }
    if (keyDetail.certs) {
      info.linkedResourceArray = keyDetail.certs.map((item) => {
        return getters.GET_CERT_INFO(item);
      });
    }
    return info;
  },
  GET_CERT_INFO: (state, getters) => (certDetail) => {
    const info = {
      id: certDetail.id,
      label: certDetail.label,
      icon: "card_membership",
      type: certDetail.type,
      tracked: certDetail.tracked,
      aliases: certDetail.aliases,
      common_name: certDetail.common_name,
      date_limit: moment(certDetail.date_limit, "YYYYMMDD").toDate(),
      notifications: certDetail.notifications,
    };
    if (certDetail.created) {
      info.created = Date.parse(certDetail.created);
    }
    if (certDetail.data) {
      info.data = {
        notBefore: Date.parse(certDetail.data.NotBefore),
        notAfter: Date.parse(certDetail.data.NotAfter),
        subjectName: certDetail.data.Subject.Name,
        subjectOrg: certDetail.data.Subject.Organization,
        issuerName: certDetail.data.Issuer.Name,
        issuerOrg: certDetail.data.Issuer.Organization,
        keyUsage: certDetail.data.KeyUsage,
        version: certDetail.data.Version,
        serialNumber: certDetail.data.SerialNumber,
        signatureAlgorithm: certDetail.data.SignatureAlgorithm,
      };
    }
    if (certDetail.keys) {
      info.linkedResourceArray = certDetail.keys.map((item) => {
        return getters.GET_KEY_INFO(item);
      });
    }
    info.hsmBlockedList = certDetail.hsmBlockedList || [];
    return info;
  },
  GET_IS_CERT_BLOCKED: () => (cert) => {
    return cert.hsmBlockedList.some((item) => item.isBlockedIn);
  },
  GET_CHAIN_INFO: () => (chainDetail) => {
    const info = {
      id: chainDetail.id,
      label: chainDetail.label,
      type: chainDetail.type,
      created: Date.parse(chainDetail.created),
      aliases: chainDetail.aliases,
      icon: "mdi-set-all",
    };
    return info;
  },
  GET_CREATION_OPTIONS: (state) => {
    let options = [];
    Object.entries(state.keyTypeOptions).forEach(([key, prop]) => {
      options.push({
        text: prop,
        value: key,
      });
    });
    return options;
  },
  GET_KEYS: (state, getters) => {
    if (!state.keysList) return [];
    return state.keysList.map((item) => {
      return getters.GET_KEY_INFO(item);
    });
  },
  GET_CERTS: (state, getters) => {
    if (!state.certList) return [];
    return state.certList.map((item) => {
      return getters.GET_CERT_INFO(item);
    });
  },
  GET_RESOURCES: (state, getters) => {
    if (!state.resourceList) return [];
    return state.resourceList.map((item) => {
      return item.type === "key" ?  getters.GET_KEY_INFO(item) : getters.GET_CERT_INFO(item);
    });
  },
  GET_CHAINS: (state, getters) => {
    if (!state.chainList) return [];
    return state.chainList.map((item) => {
      return getters.GET_CHAIN_INFO(item);
    });
  },
  CAN_EMIT_CSR: () => (family) => {
    const csrEnabledFamily = ["bpp256r1", "rsa2048", "rsa3072", "secp256r1"];
    return csrEnabledFamily.includes(family);
  },
};

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]));
  },
  SET_CERT_LIST(state, payload) {
    if (!payload) return;
    state.certList = payload.sort(dateSort);
  },
};

const actions = {
  doResetState({ commit }) {
    commit("RESET_STATE");
  },
  doGetHsmsForBlockedCert({ dispatch, rootState }, { id }) {
    let url = `${process.env.VUE_APP_API_BACKEND_URL}/api/certificate/${id}/block`;
    return new Promise((resolve, reject) => {
      let headers = { Authorization: `${sessionStorage.getItem("authToken")}` };
      request
        .get(url)
        .set(headers)
        .end(async (err, res) => {
          if (err) {
            dispatch("error/showErrorNotification", err.response.body, {
              root: true,
            }).then((error) => {
              return reject(error);
            });
          } else {
            const hsmBlockedList = JSON.parse(res.text) || [];
            const hsmList = rootState.hsm.hsmList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                isBlockedIn: false,
              };
            });

            hsmList.forEach((hsm) => {
              const hsmIsBloqued = hsmBlockedList.some(
                (hsmBlocked) => hsmBlocked.id === hsm.id
              );
              hsm.isBlockedIn = hsmIsBloqued;
            });
            return resolve({ hsmBlockedList: hsmList });
          }
        });
    });
  },
  doBlockNewCertInHsm({ dispatch }, { file, certLabel, hsmId }) {
    return new Promise((resolve, reject) => {
      dispatch("doImportCert", { body: { file, certLabel } })
        .then((res) => {
          if (res.id) {
            dispatch("doBlockCertInHsm", {
              routeParams: { certId: res.id, hsmId },
            }).then(() => {
              resolve();
            });
          } else {
            reject("unable_to_block");
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateHsmBlockStateInList({ dispatch }, { certId, hsmList }) {
    let promiseArray = [];
    hsmList.forEach((item) => {
      if (item.blockStatusHasChanged) {
        if (item.isBlockedIn) {
          promiseArray.push(
            dispatch("doBlockCertInHsm", {
              routeParams: { certId: certId, hsmId: item.id },
            })
          );
        } else {
          promiseArray.push(
            dispatch("doUnblockCert", {
              routeParams: { certId: certId, hsmId: item.id },
            })
          );
        }
      }
    });
    return Promise.all(promiseArray).catch((err) => {
      dispatch("error/showErrorNotification", err.response.body, {
        root: true,
      }).then((error) => {
        console.log(error);
      });
    });
  },
  setState({ commit }, newState) {
    commit("SET_STATE", newState);
  },
};

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      "resources",
      process.env.VUE_APP_API_BACKEND_URL
    );
});

export { state, getters, mutations, actions };
