import { treatActionsRoutes } from '../../utils'
import { mapHSMObject } from '../hsm.store'

const defaultState = {
  hsmList: {},
}

const state = { ...defaultState }

const routes = [
  {
    state: 'hsmList',
    method: 'GET',
    route: '/hsm/admin',
    action: 'doGetAdminHsms',
  },
  {
    method: 'GET',
    route: '/hsm/{id}/admin',
    action: 'doGetAdminHsm',
  },
]

const getters = {
  GET_HSM_LIST: (state) => {
    return state?.hsmList?.data?.map((item) => {
      return mapHSMObject(item)
    })
  },
  GET_HSM_LIST_TOTAL: (state) => {
    return state?.hsmList?.count || 0
  },
}

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]))
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
  },
}

const actions = {
  init() {},
  doResetState({ commit }) {
    commit('RESET_STATE')
  },
}

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      'adminHsms',
      process.env.VUE_APP_PORTAL_BACKEND_URL
    )
})

export { state, getters, mutations, actions }
