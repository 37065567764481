/* eslint-disable */
import {
  getMonthLastDay,
  getMonthFirstDay,
  occurToOfferMap,
  CRIPT_OFFER,
  PIX_OFFER,
  LGPD_OFFER,
  ALERT_SMS_OFFER,
  ALERT_EMAIL_OFFER,
  ALERT_WA_OFFER
} from '@/plugins/globals.plugin'

const getDefaultState = () => {
  return {
    chartsDateRangeOption: {
      id: 'this_month',
      title: 'this_month',
      range: {
        start: getMonthFirstDay(),
        end: getMonthLastDay()
      }
    },
    chartsStartDate: getMonthFirstDay(),
    chartsEndDate: getMonthLastDay(),
  }
}

const state = {
  chartsDateRangeOption: null,
  chartsStartDate: null,
  chartsEndDate: null,
}

const getters = {
  GET_ACT_CHARTABLE_OCCURS: () => (offersList) => {
    // Defines the custom order
    const offerResolution = [
      occurToOfferMap[CRIPT_OFFER],
      occurToOfferMap[PIX_OFFER],
      occurToOfferMap[LGPD_OFFER],
      occurToOfferMap[ALERT_SMS_OFFER],
      occurToOfferMap[ALERT_EMAIL_OFFER],
      occurToOfferMap[ALERT_WA_OFFER],
    ]
    let customOrderOffers = []
    for (const prop of offerResolution) {
      for (const offer of offersList) {
        if (offer.items.includes(prop)) {
          customOrderOffers.push(offer)
          break
        }
      }
    }
    const contractedOccurs = customOrderOffers.map(offer => {
      return offer.items
    })
    return contractedOccurs.flat()
  },
  GET_OCC_WORK_LOAD_CHART_DATA: (state, getters, rootState) => (actChartableOccurs) => {
    if (!rootState.liveOccur.occLogs.length) return []
    // The most recent message
    const currentLog = rootState.liveOccur.occLogs[0]

    return actChartableOccurs.map(occType => {
      const occLogItem = currentLog.data.find(item => item.T === occType)
      if (occLogItem) return occLogItem.C
      else return 0
    })
  },
  GET_OCCUR_TYPE_TIME_SERIE_DATA: (state, getters, rootState) => (occType) => {
    if (!rootState.liveOccur.occLogs.length) return []
    const data = rootState.liveOccur.occLogs.map(item => {
      const occLogItem = item.data.find(item => item.T === occType)
      if (occLogItem) return { x: item.time, y: occLogItem.C }
      else return { x: item.time, y: 0 }
    })
    return data
  },
  getDateRangeOptions: () => {
    let today = new Date()

    let monthBack = new Date()
    monthBack.setMonth(monthBack.getMonth() - 1)

    let weekBack = new Date()
    weekBack.setDate(weekBack.getDate() - 7)

    let sixtyDaysBack = new Date()
    sixtyDaysBack.setDate(weekBack.getDate() - 60)

    let ninetyDaysBack = new Date()
    ninetyDaysBack.setDate(weekBack.getDate() - 90)

    return [
      {
        id: 'this_month',
        title: 'this_month',
        range: {
          start: getMonthFirstDay(),
          end: getMonthLastDay()
        }
      },
      {
        id: 'last_month',
        title: 'last_month',
        range: {
          start: getMonthFirstDay(monthBack),
          end: getMonthLastDay({ aDate: monthBack })
        }
      },
      {
        id: 'last_7_days',
        title: 'last_7_days',
        range: {
          start: weekBack,
          end: today
        }
      },
      {
        id: 'more_options',
        title: 'more_options',
        children: [
          {
            id: 'last_60_days',
            title: 'last_60_days',
            range: {
              start: sixtyDaysBack,
              end: today
            }
          },
          {
            id: 'last_90_days',
            title: 'last_90_days',
            range: {
              start: ninetyDaysBack,
              end: today
            }
          }
        ]
      },
      {
        id: 'custom',
        title: 'custom',
        action: true
      }
    ]
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  SET_CHARTS_RANGE_OPTION(state, payload) {
    if (payload) state.chartsDateRangeOption = payload
    else {
      const rangeDefaultOption = {
        id: 'this_month',
        title: 'this_month',
        range: {
          start: getMonthFirstDay(),
          end: getMonthLastDay()
        }
      }
      state.chartsDateRangeOption = rangeDefaultOption
    }
  },
  SET_CHARTS_START_DATE(state, payload) {
    if (payload) state.chartsStartDate = payload
    else {
      state.chartsStartDate = getMonthFirstDay()
    }
  },
  SET_CHARTS_END_DATE(state, payload) {
    if (payload) state.chartsEndDate = payload
    else {
      state.chartsEndDate = getMonthLastDay()
    }
  }
}

const actions = {
  init({ commit }) {
    commit('resetState')
  },
  doResetState({ commit }) {
    commit('resetState')
  },
  setChartsDateOption({ commit }, option) {
    commit('SET_CHARTS_RANGE_OPTION', option)
  },
}

export { state, getters, mutations, actions }