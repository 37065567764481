export function getFrom(value, path, defaultValue = null, filter = null) {
    const invalidValues = [undefined, null, '', [], {}]
    if (invalidValues.includes(value)) {
        return defaultValue
    }

    if (typeof path === 'string') {
        path = path.split('.')
    }

    if (path.length > 1) {
        return getFrom(value[path.shift()], path, defaultValue, filter)
    }

    const result = value[path[0]] || defaultValue
    if (filter) {
        if (typeof filter === 'function') {
            return filter(result)
        } else {
            console.warn('Filter must be a function!')
        }
    }
    return result
}
