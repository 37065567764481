import i18n from '../../plugins/i18n.plugin'
import { getDateString } from '../../plugins/globals.plugin'

import { treatActionsRoutes } from '../utils'

const defaultState = {
    cksOptions: [],
    storageOptions: [],
    vformatOptions: [],
    vaultList: [],
    engineList: [],
    tokenList: []
}

const state = { ...defaultState }

const routes = [
    // VAULT
    {
        method: 'POST',
        route: '/api/svault',
        action: 'doCreateVault',
    },
    {
        method: 'DELETE',
        route: '/api/svault/{id}',
        action: 'doDeleteVault',
    },
    {
        state: 'vaultList',
        method: 'GET',
        route: '/api/svault',
        action: 'doGetVaultList',
    },
    // ENGINE
    {
        method: 'POST',
        route: '/api/svault/engine',
        action: 'doCreateEngine',
    },
    {
        method: 'DELETE',
        route: '/api/svault/engine/{id}',
        action: 'doDeleteEngine',
    },
    {
        state: 'engineList',
        method: 'GET',
        route: '/api/svault/engine',
        action: 'doGetEngineList',
    },
    {
        method: 'GET',
        route: '/api/svault/engine/{id}',
        action: 'doGetEngineDetail',
    },
    // TOKEN
    {
        method: 'POST',
        route: '/api/svault/token/{vaultId}',
        action: 'doDecodeToken',
    },
    {
        method: 'POST',
        route: '/api/svault/encode/{vault}/{engine}',
        action: 'doEncode',
    },
    {
        method: 'DELETE',
        route: '/api/svault/token/{vaultId}',
        action: 'doDeleteToken',
    },
    {
        state: 'tokenList',
        method: 'GET',
        route: '/api/svault/token/{id}',
        action: 'doGetTokenList',
    },
    // For tokens stored externally
    {
        method: 'POST',
        route: '/api/svault/decode/{vaultId}',
        action: 'doDecode',
    },
    // OPTIONS
    {
        state: 'cksOptions',
        method: 'GET',
        route: '/api/data/checksum',
        action: 'doGetCksOptions',
    },
    {
        state: 'storageOptions',
        method: 'GET',
        route: '/api/data/storagetype',
        action: 'doGetStorageOptions',
    },
    {
        state: 'vformatOptions',
        method: 'GET',
        route: '/api/data/vformat',
        action: 'doGetVformatOptions',
    },
]

const getters = {
    // Options
    GET_CKS_OPTIONS: state => {
        let options = []
        Object.entries(state.cksOptions).forEach(([key]) => {
            options.push({
                text: i18n.t(`checksum.${key}`),
                value: key
            })
        })
        return options
    },
    GET_STORAGE_OPTIONS: state => {
        let options = []
        Object.entries(state.storageOptions).forEach(([key, prop]) => {
            options.push({
                text: prop,
                value: key
            })
        })
        return options
    },
    GET_VFORMAT_OPTIONS: state => {
        let options = []
        Object.entries(state.vformatOptions).forEach(([key]) => {
            options.push({
                text: i18n.t(`vformat.${key}`),
                value: key
            })
        })
        return options
    },
    FAST_OPTIONS: () => {
        let options = [
            {
                text: i18n.t('checksum.cpf'),
                value: {
                    format: 'base10',
                    checksum: 'cpf'
                }
            },
            {
                text: i18n.t('checksum.cnpj'),
                value: {
                    format: 'base10',
                    checksum: 'cnpj'
                }
            },
            {
                text: i18n.t('checksum.pan'),
                value: {
                    format: 'base10',
                    checksum: 'pan'
                }
            },
        ]
        return options
    },
    GET_CONDENSED_OPTIONS: () => {
        let options = [
            {
                text: i18n.t('checksum.cpf'),
                value: {
                    format: 'base10',
                    checksum: 'cpf'
                }
            },
            {
                text: i18n.t('checksum.cnpj'),
                value: {
                    format: 'base10',
                    checksum: 'cnpj'
                }
            },
            {
                text: i18n.t('checksum.iel'),
                value: {
                    format: 'base10',
                    checksum: 'iel'
                }
            },
            {
                text: i18n.t('checksum.pan'),
                value: {
                    format: 'base10',
                    checksum: 'pan'
                }
            },
            {
                text: i18n.t('vformat.base10'),
                value: {
                    format: 'base10',
                    checksum: 'nocheck'
                }
            },
            {
                text: i18n.t('vformat.base62'),
                value: {
                    format: 'base62',
                    checksum: 'nocheck'
                }
            },
        ]

        return options
    },

    GET_TOKEN_LIST: state => {
        if (!state.tokenList) return []
        return state.tokenList.map(item => {
            return {
                token: item.token,
                // createdAt: Date.parse(item.CreatedAt),
            }
        })
    },
    GET_VAULT_LIST: () => {
        if (!state.vaultList) return []
        else return state.vaultList.map(item => {
            return {
                ...item,
                icon: 'mdi-safe'
            }
        })
    },
    GET_ENGINE_LIST: (state) => {
        if (!state.engineList) return []
        else return state.engineList.map(item => {
            return {
                ...item,
                icon: 'settings'
            }
        })
    },
    GET_ENGINE_COMPACT_LIST: (state) => {
        if (!state.engineList) return []
        return state.engineList.map(item => {
            return {
                text: `${item.id} - ${item.label}`,
                value: item.id
            }
        })
    },

    GET_CONDENSED_INFO: () => (engineDetail) => {
        let items = []
        if (engineDetail.id) {
            items.push(
                {
                    title: 'ID',
                    data: engineDetail.id
                }
            )
        }
        if (engineDetail.label) {
            items.push(
                {
                    title: i18n.t(`label`),
                    data: engineDetail.label
                }
            )
        }
        if (engineDetail.format && engineDetail.checksum) {
            let infoItem = { title: i18n.t(`engine_checksum`) }

            if (engineDetail.format === 'base10') {
                if (engineDetail.checksum === 'nocheck') {
                    infoItem.data = i18n.t('vformat.base10')
                } else {
                    infoItem.data = i18n.t(`checksum.${engineDetail.checksum}`)
                }
            } else if (engineDetail.format === 'base62') {
                infoItem.data = i18n.t('vformat.base62')
            }
            items.push(infoItem)
        }
        if (engineDetail.storage) {
            items.push(
                {
                    title: i18n.t(`engine_storage`),
                    data: engineDetail.storage === 'external' ? i18n.t('external_storage') : i18n.t('internal_storage')
                }
            )
        }
        if (engineDetail.created) {
            items.push(
                {
                    title: i18n.t(`engine_created`),
                    data: getDateString({ date: Date.parse(engineDetail.created) })
                }
            )
        }

        return items
    }
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach(key => state[key] = newState[key])
    },
    RESET_STATE(state) {
        Object.keys(state).forEach(key => state[key] = defaultState[key])
    },
}

const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    }
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = { body: {}, queryString: {}, routeParams: {} }
    ) => treatActionsRoutes(
        item,
        body,
        queryString,
        routeParams,
        commit,
        defaultState,
        "svault",
        process.env.VUE_APP_API_BACKEND_URL
    )
})

export { state, getters, mutations, actions }