<template>
  <v-container class="bg-gray" fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="4">
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
