<template>
  <v-app>

    <v-container v-if="isStaging || isLocal" class="col-12 ma-0 pa-0 align-self-center" id="env-info" style="position: fixed;z-index: 1;">
      <v-card
          flat
          tile
          :color="isStaging ? 'red' : 'orange'"
          class="white--text text-center text-h6"
      >
        {{ isStaging ? "DEV" : "COMPOSE" }}
      </v-card>
    </v-container>

    <app-top-bar :class="{'mt-8': isStaging || isLocal }" v-if="isLogged" />

    <template v-if="isLogged && needsMainDrawer">
      <app-drawer
        name="notifications"
        :app="true"
        :right="true"
        :clipped="false"
        :fixed="true"
        :scroll="false"
        :temporary="true"
      />
      <app-drawer
        :class="{ 'drawer-dev': isStaging || isLocal }"
        name="mainMenu"
        :app="true"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :mini-variant="$vuetify.breakpoint.mdAndUp && mainDrawerIsMini"
        :simple-footer="!mainDrawerIsMini"
      />
    </template>

    <v-main :class="{'mt-8': isStaging || isLocal }">
      <transition name="fade" mode="out-in">
        <component :is="layout">
          <router-view />
        </component>
      </transition>
      <app-snackbar />
    </v-main>
    <app-loading-modal />
    <vue-snotify />
    <v-dialog persistent :value="false" max-width="700px">
      <component :is="modalComponent" />
    </v-dialog>

    <cert-detail-modal
      readonly
      :open="showCertDetailModal"
      :object-id="selectedObjId"
      @linked="openLinkedResource"
      @close="closeAnyModal"
    />

    <create-key-modal
      readonly
      :open="showCreateModal"
      @close="closeAnyModal"
      @linked="openLinkedResource"
      :selected-obj-id="selectedObjId"
    />

    <hsm-detail-modal
      readonly
      :open="showHsmDetailModal"
      :id="selectedObjId"
      @close="closeAnyModal"
    />
  </v-app>
</template>

<script>
import AppFooter from "@/components/AppFooter/AppFooter";
import AppTopBar from "@/components/AppTopBar/AppTopBar";
import AppDrawer from "@/components/drawers/AppDrawer";
import AppLoadingModal from "@/components/AppLoadingModal";
import AppSnackbar from "@/components/AppSnackbar";
import AppHeaderAlert from "@/components/AppHeaderAlert";
import CreateKeyModal from "@/pages/ObjectsPages/modals/key/CreateKeyModal.vue";
import CertDetailModal from "@/pages/ObjectsPages/modals/certificate/CertDetailModal.vue";
import HsmDetailModal from "@/pages/AccountManagementPages/HsmPages/modals/HsmDetailModal.vue";

export default {
  components: {
    AppFooter,
    AppTopBar,
    AppDrawer,
    AppLoadingModal,
    AppSnackbar,
    AppHeaderAlert,
    CreateKeyModal,
    CertDetailModal,
    HsmDetailModal,
  },
  mounted() {
    this.$vuetify.theme.dark = this.darkMode;
  },
  data() {
    return {
      showCreateModal: false,
      showCertDetailModal: false,
      showHsmDetailModal: false,
    };
  },
  computed: {
    isStaging() {
      return this.$store.getters["ui/GET_API_BASE_IS_STAGING"];
    },
    isLocal() {
      return this.$store.getters["ui/GET_API_BASE_IS_LOCAL"];
    },
    mainDrawerIsMini() {
      return this.$store.state.drawers.mainIsInMiniVariant;
    },
    selectedObj: {
      get() {
        return this.$store.state.resourceModal.selectedResource;
      },
      set(val) {
        this.$store.commit("resourceModal/SET_SELECTED_RESOURCE", val);
      },
    },
    selectedObjId() {
      if (
        this.selectedObj &&
        Object.prototype.hasOwnProperty.call(this.selectedObj, "id")
      ) {
        return this.selectedObj.id;
      } else return null;
    },
    isLogged() {
      return this.$store.state.auth.isLogged;
    },
    locale() {
      return this.$store.state.locale.locale;
    },
    layout() {
      return this.$store.state.ui.layout;
    },
    modalComponent() {
      return () => import("@/components/WorkaroundComp");
    },
    darkMode() {
      return this.$store.getters["ui/GET_IS_DARK_MODE_ACTIVE"];
    },
    needsMainDrawer() {
      return this.$store.getters["drawers/getPathNeedsDrawer"](
        this.$route.name
      );
    },
    keyItems() {
      return this.$store.getters["resources/GET_KEYS"];
    },
    certItems() {
      return this.$store.getters["resources/GET_CERTS"];
    },
    chainItems() {
      return this.$store.getters["resources/GET_CHAINS"];
    },
    allHsms() {
      return this.$store.getters["hsm/GET_HSM_LIST"];
    },
  },
  methods: {
    closeAnyModal() {
      this.showHsmDetailModal = false;
      this.showCertDetailModal = false;
      this.showCreateModal = false;
      this.selectedObj = null;
    },
    goToResource(resource) {
      this.closeAnyModal();
      this.$router.push({ name: "certs-keys", params: { id: resource.id } });
    },
    openLinkedResource(resource) {
      this.closeAnyModal();
      this.selectedObj = { id: resource.id, objType: resource.type };
    },
  },
  watch: {
    $route({ path, meta }) {
      if (
        sessionStorage.getItem("token") !== null &&
        path !== "/tfa" &&
        !meta.publicOnly
      ) {
        if (!this.$store.getters["account/GET_USER_INFO"].email) {
          this.$store.dispatch("account/doGetUserInfo").then(({ tfa }) => {
            if (tfa === false) this.$router.push({ name: "tfa" });
          });
        }
      }
    },
    selectedObj(val) {
      if (!val) return;
      if (
        (val.objType === "hsm" &&
          !this.allHsms.find((item) => item.id === val.id)) ||
        (val.objType === "x509" &&
          !this.certItems.find((item) => item.id === val.id)) ||
        (val.objType === "pkcs7" &&
          !this.chainItems.find((item) => item.id === val.id)) ||
        (val.objType === "key" &&
          !this.keyItems.find((item) => item.id === val.id))
      ) {
        this.$notify.error({ message: this.$t("resource_not_found") });
        return;
      }

      if (val && val.objType === "key") {
        this.showCreateModal = true;
      } else if (val && val.objType === "x509") {
        this.showCertDetailModal = true;
      } else if (val && val.objType === "hsm") {
        this.showHsmDetailModal = true;
      }
    },
    locale(val) {
      this.$i18n.locale = val;
    },
    darkMode(val) {
      this.$vuetify.theme.dark = val;
    },
    isLogged(val) {
      if (!val) {
        this.$store.commit("ui/CLEAR_ALL_INTERVALS");
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "assets/styles/main";

.drawer-dev nav[name="mainMenu"] {
  top: 32px !important;
}

</style>
