<template>
  <div class="text-black-important">
    <div class="rounded-border">
      <h3 class="mt-2 mb-4" :class="{'white--text': $vuetify.theme.dark}">
        {{ isEdit ? $t("update_alias") : $t("create_alias") }}
      </h3>
      <v-text-field
        id="aliasModalValueField"
        outlined
        autocomplete="off"
        :label="$tc('alias')"
        :loading="loading"
        v-model="value"
        @keydown.space.prevent
      >
        <template v-if="isEdit" v-slot:append>
          <append-inner-button
            icon="close"
            tooltip="cancel"
            :action="closeUpdate"
            actionArg="aliasModalValueField"
          />
        </template
        >
      </v-text-field>

      <v-row no-gutters>
        <v-btn
          id="submitBtn"
          @click="() => (this.isEdit ? this.update() : this.create())"
          dark
          block
        >
          {{ isEdit ? $tc("update") : $tc("create") }}
        </v-btn>
      </v-row>

      <template v-if="aliases && aliases.length">
        <v-data-table id="AliasDataTable" :headers="headers" :items="aliases">
          <template v-slot:body="{ items: aliases }">
            <tbody>
            <tr v-for="(item, index) in aliases" :key="item.value">
              <td>{{ item.value }}</td>
              <td class="d-flex justify-end">
                <v-icon
                  id="editBtn"
                  class="cursor-pointer mr-2"
                  @click="() => openUpdate(item.value)"
                >edit
                </v-icon
                >
                <v-icon
                  v-if="index !== 0"
                  id="deleteBtn"
                  class="cursor-pointer"
                  @click="() => openDelete(item.value)"
                >delete
                </v-icon
                >
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <div class="my-2 text-center">
          <v-card outlined>
            <v-row no-gutters justify="center" class="pa-4 pb-4">
              <i>{{ $t("no_aliases") }}</i>
            </v-row>
          </v-card>
        </div>
      </template>
    </div>

    <confirm-modal
      :open="showDeleteModal"
      :title="$t('remove')"
      :message="`${$t('confirm_remove')} ${this.oldValue}`"
      @negative="closeDelete"
      @positive="remove"
    />
  </div>
</template>
<script>
import {AppendInnerButton, ConfirmModal} from "@dinamonetworks/gui-comps-lib";
import NoDataCard from "@/components/NoDataCard";

export default {
  components: {
    AppendInnerButton,
    ConfirmModal,
    NoDataCard,
  },
  props: ["refId", "aliases"],
  data() {
    return {
      value: "",
      oldValue: "",
      isEdit: false,
      loading: false,
      headers: [
        {text: "Alias", value: "value", sortable: false},
        {text: ""},
      ],
      showDeleteModal: false,
    };
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("alias/doCreateAlias", {
          body: {
            value: this.value,
            ref: this.refId,
          },
        })
        .then(({message}) => {
          this.$notify.success({message});
          this.$emit("getInfo");
        })
        .finally(() => {
          this.loading = false;
        });
      this.value = "";
    },
    update() {
      this.loading = true;
      this.$store
        .dispatch("alias/doUpdateAlias", {
          body: {
            value: this.value,
          },
          routeParams: {
            refId: this.refId,
            searchValue: this.oldValue,
          },
        })
        .then(({message}) => {
          this.$notify.success({message});
          this.$emit("getInfo");
          this.closeUpdate();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove() {
      this.loading = true;
      this.$store
        .dispatch("alias/doDeleteAlias", {
          routeParams: {
            refId: this.refId,
            searchValue: this.oldValue,
          },
        })
        .then(({message}) => {
          this.$notify.success({message});
          this.$emit("getInfo");
          this.closeDelete();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openUpdate(value) {
      this.isEdit = true;
      this.oldValue = value;
      this.value = value;
    },
    closeUpdate() {
      this.isEdit = false;
      this.oldValue = "";
      this.value = "";
    },
    openDelete(value) {
      this.oldValue = value;
      this.showDeleteModal = true;
    },
    closeDelete() {
      this.oldValue = "";
      this.showDeleteModal = false;
    },
  },
};
</script>
<style>
.text-black-important {
  color: rgba(0, 0, 0, 0.87) !important;
}

.rounded-border {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 8px;
  margin-top: 8px;
}
</style>
