<template>
  <modal
    parent-name="CertDetailModal"
    v-bind="$props"
    @close="closeModal"
    :max-width="notifications && notifications.length ? '700px' : '600px'"
  >
    <template v-slot:title>
      {{ readonly ? $tc("cert_details") : $tc("edit_cert") }}
    </template>

    <template v-slot:text>
      <template v-if="loading">
        <v-card flat min-height="200px">
          <v-row align="center" justify="center" class="h-20v">
            <v-progress-circular indeterminate :size="50" />
          </v-row>
        </v-card>
      </template>
      <template v-else>
        <v-tabs :ripple="false" v-model="tab" background-color="transparent">
          <v-tab
            v-for="tab in tabs"
            v-text="tab"
            :key="`tab-${tab}`"
            :id="tab"
          />
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-text-field
              class="mt-4"
              id="CertDetailModal_id"
              label="ID"
              outlined
              readonly
              :value="objectId"
            >
              <template v-slot:append>
                <append-inner-button
                  icon="content_copy"
                  tooltip="copy"
                  :action="doCopy"
                  actionArg="CertDetailModal_id"
                />
              </template>
            </v-text-field>

            <div class="rounded-border" style="margin-bottom: 8px">
              <div>{{ $tc("alias_list") }}</div>
              <v-list dense>
                <template v-if="aliases && aliases.length">
                  <v-list-item v-for="(item, i) in aliases" :key="i">
                    <v-list-item-icon>
                      <v-icon>local_offer</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.value"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-else>
                  <div class="my-2 text-center">
                    <i>{{ $t("no_aliases") }}</i>
                  </div>
                </template>
              </v-list>
            </div>

            <template v-for="(linkedResource, i) in linkedResourceArray">
              <resource-chip
                :resource-type="linkedResource.type"
                :text="linkedResource.id"
                :key="i"
                @click="openLinkedResource(linkedResource)"
              />
            </template>

            <content-area :items="unmutableInfo" class="px-1" />
          </v-tab-item>
          <v-tab-item>
            <cert-info-tab class="mt-4" :data="certData" />
          </v-tab-item>
          <v-tab-item v-if="!readonly">
            <alias-tab
              @getInfo="getInfo"
              :refId="objectId"
              :aliases="aliases"
            />
          </v-tab-item>
          <v-tab-item v-if="!readonly">
            <notifications-tab
              @getInfo="getInfo"
              :refId="objectId"
              :notifications="notifications"
            />
          </v-tab-item>
          <v-tab-item v-if="needsTrackersTab">
            <cert-trackers-tab
              :trackers="tracked"
              :objectId="objectId"
              @remove-tracker="$emit('remove-tracker', $event)"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </template>
  </modal>
</template>

<script>
import { Modal } from "@dinamonetworks/gui-comps-lib";
import ContentArea from "@/components/ContentArea";
import ResourceChip from "@/components/ResourceChip";
import { AppendInnerButton } from "@dinamonetworks/gui-comps-lib";
import NoDataCard from "@/components/NoDataCard";

import AliasTab from "../AliasTab.vue";
import CertInfoTab from "./tabs/CertInfoTab";
import CertTrackersTab from "./tabs/CertTrackersTab";
import NotificationsTab from "./tabs/NotificationsTab.vue";

export default {
  components: {
    AliasTab,
    CertInfoTab,
    CertTrackersTab,
    ContentArea,
    AppendInnerButton,
    ResourceChip,
    Modal,
    NoDataCard,
    NotificationsTab,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    objectId: {
      type: String,
      required: false,
      default: null,
    },
    readonly: Boolean,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      updating: false,
      tab: 0,
      certData: null,
      created: null,
      linkedResourceArray: null,
      tracked: null,
      aliases: [],
      notifications: [],
    };
  },
  computed: {
    needsTrackersTab() {
      return this.tracked;
    },
    tabs() {
      let tabs = [this.$tc("detail", 2), this.$tc("cert_info")];
      if (!this.readonly) {
        tabs.push(this.$tc("alias"));
        tabs.push(this.$tc("notification", 2));
      }

      if (this.needsTrackersTab) tabs.push(this.$tc("alert", 2));

      return tabs;
    },
    unmutableInfo() {
      return [
        {
          title: this.$tc("created_at"),
          data: this.created,
          id: "created",
        },
      ];
    },
    // ERRORS
  },
  validations() {
    const validations = {};
    return validations;
  },
  methods: {
    openLinkedResource(resource) {
      this.$emit("linked", resource);
    },
    doCopy(elementId) {
      const ele = `#${elementId}`;
      var copyText = document.querySelector(ele);
      copyText.select();
      document.execCommand("copy");
      this.$store.dispatch("ui/showSnackbar", {
        message: this.$t("copied"),
        position: 3,
      });
    },
    getInfo() {
      this.loading = true;
      this.$store
        .dispatch("resources/doGetCert", { routeParams: { id: this.objectId } })
        .then((res) => {
          const certInfo = this.$store.getters["resources/GET_CERT_INFO"](res);
          this.created = this.$getDateString({ date: certInfo.created });
          this.certData = certInfo.data;
          this.tracked = certInfo.tracked;
          this.linkedResourceArray = certInfo.linkedResourceArray;
          this.aliases = certInfo.aliases;
          this.notifications = certInfo.notifications;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearData() {
      this.created = null;
      this.certData;
      this.tab = 0;
    },
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    open(val) {
      this.dialog = val;
      if (!val) {
        this.clearData();
      } else {
        this.getInfo();
      }
    },
  },
};
</script>

<style>
#certAliasListId {
  position: absolute;
  font-size: 0.8rem;
  left: 0.1;
  top: 196px;
  transform: translateY(-50%);
  background-color: white;
  color: rgb(80, 78, 78);
  padding: 0 0.5rem;
  margin: 0 0.25rem;
  transform-origin: left top;
  pointer-events: none;
}
</style>