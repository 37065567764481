import i18n from '../plugins/i18n.plugin'

export function dateTimeFormat(value, locale) {
    if (!value) return null
    return i18n.formatDateTime(value, locale)
}

export function dateFormat(value, locale) {
    if (!value) return null
    return i18n.formatDate(value, locale)
}

export function timeFormat(value, locale) {
    if (!value) return null
    return i18n.formatTime(value, locale)
}