import { treatActionsRoutes } from '../../utils'

const defaultState = {
    accountVersion: null,
    apiVersion: null,
    billingVersion: null,
    contractVersion: null,
    docusignVersion: null,
    hsmVersion: null,
    monitorVersion: null,
    offerVersion: null,
    senderVersion: null,
    signerVersion: null,
    brokerVersion: null,
    hyperVersion: null,
    syncerVersion: null,
}

const state = { ...defaultState }

const routes = [
    {
        method: 'GET',
        state: 'accountVersion',
        route: '/account/version',
        action: 'doGetAccountVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'apiVersion',
        route: '/api/version',
        action: 'doGetApiVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'billingVersion',
        route: '/billing/version',
        action: 'doGetBillingVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'contractVersion',
        route: '/contract/version',
        action: 'doGetContractVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'docusignVersion',
        route: '/docusign/version',
        action: 'doGetDocusignVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'hsmVersion',
        route: '/hsm/version',
        action: 'doGetHsmVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'monitorVersion',
        route: '/monitor/version',
        action: 'doGetMonitorVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'offerVersion',
        route: '/offer/version',
        action: 'doGetOfferVersion',
        baseURL: process.env.VUE_APP_PORTAL_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'senderVersion',
        route: '/api/sender/version',
        action: 'doGetSenderVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'signerVersion',
        route: '/system/info',
        action: 'doGetSignerVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'brokerVersion',
        route: '/api/status/broker',
        action: 'doGetBrokerVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'hyperVersion',
        route: '/api/status/hyper',
        action: 'doGetHyperVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
    {
        method: 'GET',
        state: 'syncerVersion',
        route: '/api/status/syncer',
        action: 'doGetSyncerVersion',
        baseURL: process.env.VUE_APP_API_BACKEND_URL,
    },
]

const getters = {}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach((key) => (state[key] = newState[key]))
    },
    RESET_STATE(state) {
        Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
    },
}

const actions = {
    init() {},
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = {
            body: {},
            queryString: {},
            routeParams: {},
        }
    ) =>
        treatActionsRoutes(
            item,
            body,
            queryString,
            routeParams,
            commit,
            defaultState,
            'adminVersion',
            item.baseURL
        )
})

export { state, getters, mutations, actions }
