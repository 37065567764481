/* eslint-disable */
import { treatActionsRoutes } from '../utils'

const defaultState = {
  hsmList: {},
  hsm: null,
  hasBindedHSM: null,
}

const state = { ...defaultState }
const API_VERSION = process?.env?.VUE_APP_PORTAL_BACKEND_API_VERSION || 'v2'

const routes = [
  {
    method: 'POST',
    route: '/hsm',
    action: 'doCreateHSM',
  },
  {
    state: 'hsmList',
    method: 'GET',
    route: `/${API_VERSION}/hsm`,
    action: 'doGetHSMList',
  },
  {
    method: 'DELETE',
    route: '/hsm/{id}',
    action: 'doDeleteHSM',
  },
  {
    state: 'hsm',
    method: 'GET',
    route: `/${API_VERSION}/hsm/{id}`,
    action: 'doGetHSM',
  },
  {
    method: 'GET',
    route: '/hsm/{id}/token',
    action: 'doGetHSMToken',
  },
  {
    method: 'PUT',
    route: '/hsm/{id}',
    action: 'doUpdateHSM',
  },
  {
    method: 'GET',
    route: '/hsm/{id}/block',
    action: 'doGetHSMBlockedList',
  },
  {
    method: 'GET',
    route: '/api/rand/{length}',
    action: 'doGenerateRandom',
  },
  {
    method: 'GET',
    route: '/billing/rentconsumerbyday/{id}',
    action: 'doGetHSMConsumer',
  },
  {
    method: 'GET',
    route: '/billing/rentconsumerbytype/{id}',
    action: 'doGetHSMConsumerTypes',
  },
  {
    method: 'GET',
    route: '/billing/rentconsumerbyday/{id}/{accountId}',
    action: 'doGetHSMConsumerAdmin',
  },
  {
    method: 'GET',
    route: '/billing/rentconsumerbytype/{id}/{accountId}',
    action: 'doGetHSMConsumerTypesAdmin',
  },
  {
    method: 'GET',
    route: '/v2/billing/rentconsumerbyday/{id}',
    action: 'doGetHSMConsumerV2',
  },
  {
    method: 'GET',
    route: '/v2/billing/rentconsumerbytype/{id}',
    action: 'doGetHSMConsumerTypesV2',
  },
  {
    method: 'GET',
    route: '/v2/billing/rentconsumerbyday/{id}/{accountId}',
    action: 'doGetHSMConsumerAdminV2',
  },
  {
    method: 'GET',
    route: '/v2/billing/rentconsumerbytype/{id}/{accountId}',
    action: 'doGetHSMConsumerTypesAdminV2',
  },
]

const getters = {
  GET_HSM_LIST: (state) => {
    return state?.hsmList?.data?.map((item) => {
      return mapHSMObject(item)
    })
  },
  GET_HSM_LIST_TOTAL: (state) => {
    return state?.hsmList?.count || 0
  },
  HSM_IS_BINDED:
    (state) =>
    ({ id }) => {
      if (state?.hsm) {
        return Boolean(
          state?.hsm?.descriptor?.Descriptor || state?.hsm?.HsmDescriptor
        )
      }

      const hsmItem = state.hsmList?.data?.find((item) => {
        return item.id === id
      })
      return Boolean(
        hsmItem && (hsmItem?.descriptor?.Descriptor || hsmItem?.HsmDescriptor)
      )
    },

  getHasBindedHSM: (state) => {
    // TODO: change condition for new field 'isLan'
    return state.hsmList?.data?.length !== 0
    // return state.hsmList?.data?.some(hsmItem => {
    //   hsmItem.isLan === true
    // })
  },
}

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]))
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
  },
}

const actions = {
  doResetState({ commit }) {
    commit('RESET_STATE')
  },
}

export const mapHSMObject = (item) => {
  let HsmDescriptor = item?.descriptor?.Descriptor
  if (!HsmDescriptor && item?.HsmDescriptor) {
    HsmDescriptor = item?.HsmDescriptor
  }

  return {
    id: item.id,
    hsmId: item?.descriptor?.HsmId,
    label: item.name,
    name: item.name,
    created: Date.parse(
      item?.CreatedAt ||
        item?.createdAt ||
        item?.descriptor?.createAt ||
        item?.descriptor?.CreateAt
    ),
    updated: Date.parse(
      item?.UpdatedAt ||
        item?.updatedAt ||
        item?.descriptor?.updateAt ||
        item?.descriptor?.UpdateAt
    ),
    isConfigured: Boolean(HsmDescriptor),
    aliases: item.aliases,
    type: HsmDescriptor?.model?.replaceAll(/[\(\)]/g, ''),
    firmwareVersion: HsmDescriptor?.ver,
    serialNumber: item?.descriptor?.NodeId,
    totalUsers: HsmDescriptor?.usrc,
    totalObjects: HsmDescriptor?.objc,
    hardwareVersion: HsmDescriptor?.hw,
    logSize: HsmDescriptor?.logl,
    bindingKey: item?.bs,
    batteryStatus: HsmDescriptor?.battery_status,
    dbSize: HsmDescriptor?.db_size,
    operationMode: HsmDescriptor?.operation_mode,
    pendingBackup: HsmDescriptor?.pending_backup,
    pendingUpack: HsmDescriptor?.pending_upack,
    uptime: HsmDescriptor?.uptime,
    storageAreaUsage: HsmDescriptor?.storage_area_usage,
    capacity: item?.capacity,
    accountInfo: {
      id: item?.account_info?.ID,
      name: item?.account_info?.Name,
      surname: item?.account_info?.Surname,
      email: item?.account_info?.Email,
      phone: item?.account_info?.Phone,
      company: item?.account_info?.Company,
    },
  }
}

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      'hsm',
      process.env.VUE_APP_PORTAL_BACKEND_URL
    )
})

export { state, getters, mutations, actions }
