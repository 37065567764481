import Vue from 'vue'
import i18n from './i18n.plugin'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import 'vue-snotify/styles/material.css'

const options = {
    toast: {
        position: SnotifyPosition.rightTop,
        titleMaxLength: 100,
        bodyMaxLength: 100,
    },
}

function _parseObjMessage(obj) {
    const dfValues = { title: null, message: null }
    if (typeof obj === 'string') {
        return { ...dfValues, message: obj }
    }
    if (!Object.keys(obj).includes('message')) {
        const message = obj?.detail || obj?.message || null
        obj = { ...obj, message }
    }
    return { ...dfValues, ...obj }
}

Vue.use(Snotify, options)
const s = new Vue()

/**
 * How to use ?
 *
 * Ex: this.$notify.success({}) // Default params
 * Ex: this.$notify.success({title: "hi", message: "hi again"})
 */
export const success = function (params) {
    let { title, message } = _parseObjMessage(params)
    message = message || i18n.t('success')

    s.$snotify.success(message, title, {
        timeout: 2000,
        showProgressBar: false,
        maxOnScreen: 1,
    })
}

/**
 * How to use ?
 *
 * Ex: this.notifyError({title: "hi", message: "hi again"})
 *
 * Ex: // Using callback
 * this.notifyError({title: "hi", message: "hi again"}, function () {
 *    // calback is called when user confirms
 *    console.log("callback called!")
 * })
 */
export const error = function (params) {
    let { title, message } = _parseObjMessage(params)
    s.$snotify.error(message, title, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        icon: false,
    })
}

export const info = function (params) {
    let { title, message } = _parseObjMessage(params)
    s.$snotify.info(message, title, {
        timeout: 2000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        icon: false,
    })
}

/**
 * How to use ?
 *
 * Ex: this.notifyConfirm({title: "hi", message: "hi again"})
 *
 * Ex: // Using callback
 * this.notifyConfirm({title: "hi", message: "hi again"}, function () {
 *    // calback is called when user confirms
 *    console.log("callback called!")
 * })
 */
export const confirm = function (params, cb) {
    let { title, message } = _parseObjMessage(params)
    s.$snotify.warning(message, title, {
        closeOnClick: false,
        showProgressBar: false,
        timeout: 60000,
        // backdrop: 0.5,
        position: 'centerCenter',
        buttons: [
            {
                text: i18n.t('confirm'),
                action: (toast) => {
                    cb()
                    s.$snotify.remove(toast.id)
                },
            },
            {
                text: i18n.t('cancel'),
                action: (toast) => {
                    s.$snotify.remove(toast.id)
                },
            },
        ],
    })
}

Vue.prototype.$notify = {
    success,
    error,
    info,
    confirm,
}
