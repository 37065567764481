<template>
  <div class="text-black-important">
    <div class="rounded-border">
      <h3 class="mb-2">
        {{ isEdit ? $t("update_notification") : $t("create_notification") }}
      </h3>
      <v-form @submit.prevent="submit">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              class="mr-4"
              id="NotificationModal_Interval"
              outlined
              :label="$t('interval')"
              v-model="interval"
              @input="$v.interval.$touch()"
              @blur="$v.interval.$touch()"
              :error-messages="intervalErrors"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              id="NotificationModal_Unit"
              append-icon="$dropdown"
              outlined
              :items="units"
              :label="$t('unit')"
              :error-messages="unitErrors"
              @input="$v.unit.$touch()"
              @blur="$v.unit.$touch()"
              v-model="unit"
            />
          </v-col>
          <v-col
            v-if="isEdit"
            cols="1"
            class="d-flex justify-content-center ml-3"
            style="padding-bottom: 30px"
          >
            <v-icon
              id="NotificationModal_CancelUpdate"
              class="cursor-pointer"
              @click="closeUpdate"
              >close</v-icon
            >
          </v-col>
        </v-row>

        <h3 style="color: gray">{{ $t("send_methods") }}</h3>
        <v-row no-gutters>
          <v-checkbox
            class="pt-0 mt-0 mr-2"
            v-for="method in sendingMethods"
            :key="method.value"
            :label="method.text"
            v-model="method.using"
          />
        </v-row>

        <v-row no-gutters>
          <v-btn
            id="submitBtn"
            type="submit"
            block
            :loading="loading"
            :disabled="$v.$invalid"
            :dark="!$v.$invalid"
          >
            {{ isEdit ? $tc("update") : $tc("create") }}
          </v-btn>
        </v-row>
      </v-form>

      <template v-if="notifications && notifications.length">
        <v-data-table :headers="headers" :items="notifications">
          <template v-slot:body="{ items: notifications }">
            <tbody>
              <tr v-for="item in notifications" :key="item.value">
                <td>{{ item.id }}</td>
                <td>{{ item.interval }}</td>
                <td>
                  {{ units.find((unit) => unit.value === item.unit).text }}
                </td>
                <td>
                  {{
                    sendingMethods
                      .filter((method) => item.methods.includes(method.value))
                      .map((method) => method.text)
                      .join(", ")
                  }}
                </td>
                <td class="d-flex justify-end">
                  <v-icon
                    id="editBtn"
                    class="cursor-pointer mr-2"
                    @click="() => openUpdate(item)"
                    >edit</v-icon
                  >
                  <v-icon
                    id="deleteBtn"
                    class="cursor-pointer"
                    @click="() => openDelete(item.id)"
                    >delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>
      <template v-else>
        <div class="my-2 text-center">
          <v-card outlined>
            <v-row no-gutters justify="center" class="pa-4 pb-4">
              <i>{{ $t("no_notifications") }}</i>
            </v-row>
          </v-card>
        </div>
      </template>
    </div>

    <confirm-modal
      :open="showDeleteModal"
      :title="$t('remove')"
      :message="`${$t('confirm_remove')} ${this.idToAlter}`"
      @negative="closeDelete"
      @positive="remove"
    />
  </div>
</template>
<script>
import { AppendInnerButton, ConfirmModal } from "@dinamonetworks/gui-comps-lib";
import NoDataCard from "@/components/NoDataCard";

import { required, integer, minValue } from "vuelidate/lib/validators";

export default {
  components: {
    AppendInnerButton,
    ConfirmModal,
    NoDataCard,
  },
  props: ["refId", "notifications"],
  data() {
    return {
      interval: null,
      unit: null,
      idToAlter: null,
      isEdit: false,
      loading: false,
      headers: [
        { text: "Id" },
        { text: this.$t("interval") },
        { text: this.$t("unit") },
        { text: this.$t("send_methods") },
        { text: "" },
      ],
      showDeleteModal: false,
      units: [
        { text: "Dias", value: "d" },
        { text: "Meses", value: "m" },
      ],
      sendingMethods: [
        { text: "Whatsapp", value: "WA", using: false },
        { text: "Email", value: "EMAIL", using: false },
        { text: "SMS", value: "SMS", using: false },
      ],
    };
  },
  computed: {
    // ERRORS
    intervalErrors() {
      const errors = [];
      if (this.$v.interval.integer === false)
        errors.push(this.$t("$validation.integer"));
      if (this.$v.interval.minValue === false)
        errors.push(this.$t("$validation.min_value", { num: 1 }));
      return errors;
    },
    unitErrors() {
      const errors = [];
      if (!this.$v.unit.$dirty) return errors;
      !this.$v.unit.required && errors.push(this.$t("$validation.required"));
      return errors;
    },
  },
  validations() {
    return {
      interval: {
        required,
        minValue: minValue(1),
        integer,
      },
      unit: {
        required,
      },
    };
  },
  methods: {
    submit() {
      if (this.isEdit) this.update();
      else this.create();
    },
    create() {
      if (
        this.sendingMethods.filter((method) => method.using === true).length <=
        0
      )
        this.$store.dispatch(
          "error/showErrorNotification",
          "Pelo menos uma forma de envio deve ser selecionada",
          {
            root: true,
          }
        );
      else {
        this.loading = true;
        this.$store
          .dispatch("notifications/doCreate", {
            routeParams: {
              certId: this.refId,
            },
            body: {
              unit: this.unit,
              interval: parseInt(this.interval),
              methods: this.sendingMethods
                .filter((method) => method.using)
                .map((method) => method.value),
            },
          })
          .then(({ message }) => {
            this.$notify.success({ message });
            this.$emit("getInfo");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    update() {
      if (
        this.sendingMethods.filter((method) => method.using === true).length <=
        0
      )
        this.$store.dispatch(
          "error/showErrorNotification",
          "Pelo menos uma forma de envio deve ser selecionada",
          {
            root: true,
          }
        );
      else {
        this.loading = true;
        this.$store
          .dispatch("notifications/doUpdate", {
            body: {
              unit: this.unit,
              interval: parseInt(this.interval),
              methods: this.sendingMethods
                .filter((method) => method.using)
                .map((method) => method.value),
            },
            routeParams: {
              certId: this.refId,
              notificationId: this.idToAlter,
            },
          })
          .then(({ message }) => {
            this.$notify.success({ message });
            this.$emit("getInfo");
            this.closeUpdate();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    remove() {
      this.loading = true;
      this.$store
        .dispatch("notifications/doDelete", {
          routeParams: {
            certId: this.refId,
            notificationId: this.idToAlter,
          },
        })
        .then(({ message }) => {
          this.$notify.success({ message });
          this.$emit("getInfo");
          this.closeDelete();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openUpdate(value) {
      this.isEdit = true;
      this.idToAlter = value.id;
      this.unit = value.unit;
      this.interval = value.interval;
      this.sendingMethods = this.sendingMethods.map((method) => ({
        ...method,
        using: value.methods.includes(method.value),
      }));
    },
    closeUpdate() {
      this.isEdit = false;
      this.idToAlter = null;
      this.unit = null;
      this.interval = null;
      this.sendingMethods = this.sendingMethods.map((method) => ({
        ...method,
        using: false,
      }));
    },
    openDelete(value) {
      this.idToAlter = value;
      this.showDeleteModal = true;
    },
    closeDelete() {
      this.idToAlter = null;
      this.showDeleteModal = false;
    },
  },
};
</script>
<style>
.text-black-important {
  color: rgba(0, 0, 0, 0.87) !important;
}

.rounded-border {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 8px;
  margin-top: 8px;
}
</style>
