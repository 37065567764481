export function truncate(str, length) {
  if (str.length > length) {
    return str.substring(0, length) + '...'
  }
  return str
}

export function truncateWords(str, length) {
  return str.split(' ').splice(0, length).join(' ')
}
