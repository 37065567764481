import { treatActionsRoutes } from "../utils";

const defaultState = {
  otp: null,
  recaptchaToken: null,
  file_encryption_treatment: false,
  google_oauth_treatment: false,
  dashboard_tlm_treatment: false,
  token: sessionStorage.getItem("token"),
  refresh_token: sessionStorage.getItem("refresh_token"),
  accountId: sessionStorage.getItem("accountId"),
  isLogged: !!sessionStorage.getItem("token"),
  email: 'teste'
};

const state = { ...defaultState };

const getters = {
  GET_TOKENS: (state) => ({
    token: state.token,
    refresh_token: state.refresh_token,
  }),

  GET_EMAIL: (state) => ({
    email: state.email,
  }),
};

const routes = [
  {
    method: "POST",
    state: "*",
    route: "/account/login",
    action: "doLogin",
    showError: false,
  },
  {
    method: "POST",
    state: "*",
    route: "/account/sign_up",
    action: "doSignUp",
  },
  {
    method: "POST",
    state: "*",
    route: "/account/login",
    action: "doVerifyPassword",
  },
  {
    method: "POST",
    route: "/account/change_password",
    action: "doChangePassword",
  },
  {
    method: "POST",
    route: "/account/resend_link",
    action: "doSendVerifyLink",
  },
  {
    method: "GET",
    route: "/account/verify_email/{activation_token}",
    action: "doVerifyEmail",
  },
  {
    method: "POST",
    route: "/account/request_reset_password",
    action: "doRequestPasswordReset",
  },
  {
    method: "POST",
    route: "/account/reset_password",
    action: "doResetPassword",
  },
];

const mutations = {
  SET_IS_LOGGED(state, payload = false) {
    state.isLogged = payload;
  },
  SET_STATE(state, newState) {
    if (newState.token) sessionStorage.setItem("token", newState.token);
    if (newState.refresh_token)
      sessionStorage.setItem("refresh_token", newState.refresh_token);
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => {
      if(key==='token' || key==='refresh_token'){
        state[key] = null
      }else if(key === 'isLogged'){
        state[key] = false
      }
    });
  },
};

const actions = {
  // startProdTools({ commit, rootState }) {
  //     // Split
  //     var SplitFactory = require('@splitsoftware/splitio').SplitFactory;
  //     var factory = SplitFactory({
  //         core: {
  //             authorizationKey: process.env.VUE_APP_SPLT_AUTH_KEY,
  //             // the split key cannot be null or empty
  //             key: rootState.account.email || 'd'
  //         }
  //     });
  //     const client = factory.client();
  //     client.on(client.Event.SDK_READY, function () {

  //         const splits = ['file_encryption', 'google_oauth', 'dashboard_tlm']
  //         splits.forEach(split => {
  //             const splitTreatment = client.getTreatment(split) === 'on'
  //             const splitName = split + '_treatment'
  //             commit('SET_SPLIT_TREATMENT', { splitName, splitTreatment })
  //         })
  //     })
  // },
  init() {
    // dispatch('startProdTools')
  },
  async doLogout({ commit, dispatch }) {
    if (!state.isLogged) {
      return;
    }

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("email");
    commit("RESET_STATE");

    dispatch("resources/doResetState", {}, { root: true });
    dispatch("hsm/doResetState", {}, { root: true });
    dispatch("account/doResetState", {}, { root: true });
    dispatch("billing/doResetState", {}, { root: true });
    dispatch("report/doResetState", {}, { root: true });
    dispatch("charts/doResetState", {}, { root: true });
    dispatch("offers/doResetState", {}, { root: true });
    dispatch("resources/doResetState", {}, { root: true });
    dispatch("logMon/doResetState", {}, { root: true });
    dispatch("liveOccur/doResetState", {}, { root: true });
    dispatch("svault/doResetState", {}, { root: true });
    dispatch("resourceModal/doResetState", {}, { root: true });
    dispatch("otp/doResetState", {}, { root: true });
  },
  // async doLoginPartner({ commit }, { b64Credentials }) {
  //     const body = {
  //         b64Credentials: b64Credentials
  //     }
  //     const url = process.env.VUE_APP_API_BASE + '/account/login/partner'
  //     return new Promise((resolve, reject) => {
  //         request
  //             .post(url)
  //             .send(body)
  //             .end(async (err, res) => {
  //                 if (err) {
  //                     return reject(err)
  //                 }
  //                 return resolve(res.body)
  //             })
  //     })
  // },
  doResetState({ commit }) {
    commit("RESET_STATE");
  },
};

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      "auth",
      process.env.VUE_APP_PORTAL_BACKEND_URL
    );
});

export { state, getters, mutations, actions };
