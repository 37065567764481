import Vue from 'vue'
import store from '@/store'
import i18n from './i18n.plugin'

Vue.prototype.$doCopy = (toCopy) => {
    let textArea = document.createElement('textarea')
    textArea.value = String(toCopy).trim()
    document.body.appendChild(textArea)
    textArea.select()
    document.execCommand('Copy')
    textArea.remove()
    store.dispatch('ui/showSnackbar', {
        message: i18n.t('copied'),
        position: 3,
    })
}
