export function cpfFormat(value) {
    if (!value) {
        return ''
    }
    return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function cnpjFormat(value) {
    if (!value) {
        return ''
    }
    return value?.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
    )
}

export function cpfCnpjFormat(value) {
    if (!value) {
        return ''
    }
    if (value.length > 11) {
        return cnpjFormat(value)
    }
    return cpfFormat(value)
}
