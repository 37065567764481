<template>
  <v-card outlined class="pa-12">
    <v-row class="pa-4 pb-0">
      <v-col>
        <v-row no-gutters justify="center" class="my-2">
          <span
            class="text-h4 font-weight-regular text-center text--disabled"
          >
            {{ title }}
          </span>
        </v-row>

        <slot name="subtitle">
          <v-row v-if="subtitle" no-gutters justify="center" class="my-2">
            <span class="text--secondary text-center">
              {{ subtitle }}
            </span>
          </v-row>
        </slot>

        <v-row no-gutters justify="center" class="my-4">
          <slot name="action">
            <v-btn
              v-if="buttonTitle"
              :id="buttonId"
              :color="$vuetify.theme.dark ? 'primary' : 'secondary'"
              @click="$emit('action')"
            >
              {{ buttonTitle }}
            </v-btn>
          </slot>
        </v-row>
      </v-col>
    </v-row>
    <v-fade-transition>
      <v-overlay
        v-if="disabled"
        absolute
        color="card_disabled"
      />
    </v-fade-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    subtitle: String,
    buttonTitle: String,
    buttonId: String,
    disabled: Boolean
  }

}
</script>

<style>

</style>