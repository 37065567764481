import { treatActionsRoutes } from '../../utils'
import { getDateString } from '@/plugins/globals.plugin'

const defaultState = {
    accountList: [],
}

const state = { ...defaultState }

const routes = [
    {
        method: 'PATCH',
        route: '/account/{id}/{field}',
        action: 'updateExtraneousAccountInfo',
    },
    {
        method: 'DELETE',
        route: '/account/{id}',
        action: 'deleteAccount',
    },
    {
        state: 'accountList',
        method: 'GET',
        route: '/account',
        action: 'listAccounts',
    },
    {
        method: 'GET',
        route: '/account/{id}',
        action: 'accountDetails',
    },
    {
        method: 'PATCH',
        route: '/account/{id}/block',
        action: 'blockAccount',
    },
    {
        method: 'PATCH',
        route: '/account/{id}/activate',
        action: 'activateAccount',
    },
    {
        method: 'PUT',
        route: '/account/{id}',
        action: 'updateAccount',
    },
    {
        method: 'DELETE',
        route: '/account/tfa/{id}',
        action: 'deleteTFA',
    },
]

const getters = {
    getAccountList: (state) => {
        return state.accountList.map((item) => ({
            ...item,
            created: getDateString({ date: item.created }),
        }))
    },
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach((key) => (state[key] = newState[key]))
    },
    RESET_STATE(state) {
        Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
    },
}

const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = {
            body: {},
            queryString: {},
            routeParams: {},
        }
    ) =>
        treatActionsRoutes(
            item,
            body,
            queryString,
            routeParams,
            commit,
            defaultState,
            'adminAccounts',
            process.env.VUE_APP_PORTAL_BACKEND_URL
        )
})

export { state, getters, mutations, actions }
