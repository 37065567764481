<template>
    <div @mouseover="mouseOver" @mouseleave="mouseLeave">
        <v-navigation-drawer
            id="sidebar-drawer"
            v-model="drawer"
            v-bind="$props"
            :class="{ 'no-overflow': !scroll }"
        >
            <v-container pa-0>
                <v-row>
                    <v-col class="py-0">
                        <component :is="component"/>
                    </v-col>
                </v-row>
            </v-container>
            <template v-if="name === 'mainMenu'" v-slot:prepend>
                <router-link :to="{ name: 'welcome' }" class="d-flex router-link-active" id="navbar-logo">
                    <img
                        height="38px"
                        :src="getLogo"
                    />
                </router-link>
            </template>
            <template v-slot:append>
                <component :is="appendComponent"/>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import {AccountMenu} from "@dinamonetworks/gui-comps-lib";

export default {
    components: {
        AccountMenu,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        width: {
            type: Number,
            default: 310,
        },
        app: {
            type: Boolean,
            default: () => false,
        },
        clipped: {
            type: Boolean,
            default: () => false,
        },
        right: {
            type: Boolean,
            default: () => false,
        },
        temporary: {
            type: Boolean,
            default: () => false,
        },
        fixed: {
            type: Boolean,
            default: () => false,
        },
        absolute: {
            type: Boolean,
            default: () => false,
        },
        permanent: {
            type: Boolean,
            default: () => false,
        },
        scroll: {
            type: Boolean,
            default: () => true,
        },
        disableMainScroll: {
            type: Boolean,
            default: () => true,
        },
        simpleFooter: {
            type: Boolean,
        },
        miniVariant: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            component: null,
        };
    },
    mounted() {
        this.$store
            .dispatch("account/doGetUserInfo")
            .then(({email}) => sessionStorage.setItem("email", email));
        this.loader()
            .then(() => {
                this.component = () => this.loader();
            })
            .catch(() => {
                this.component = () => import("./AppDefaultDrawer");
            });
    },
    computed: {
        getLogo() {
            const drawer = this.$store.state.drawers;
            if (drawer.mainIsInMiniVariant && !drawer.mainMenu) {
                return require('@/assets/images/logo/cloud-nuvem-96x96.png');
            }

            if (this.$vuetify.theme.dark) {
                return require('@/assets/images/logo/supercloud_white.png')
            }

            return require('@/assets/images/logo/supercloud_normal.png');
        },
        drawer: {
            get() {
                return this.$store.state.drawers[this.name];
            },
            set(val) {
                if (!val) {
                    const mutation = this.$store.getters["drawers/getMutation"](
                        this.name
                    );
                    this.$store.commit(`drawers/${mutation}`, false);
                }
            },
        },
        loader() {
            if (!this.name) return null;

            const component = this.$store.getters["drawers/getComponent"](this.name);
            return () => import(`./${component}`);
        },
        accountEmail() {
            return this.$store.getters["account/GET_USER_INFO"].email;
        },
        accountName() {
            return this.$store.getters["account/GET_USER_INFO"].name;
        },
        appendComponent() {
            if (this.simpleFooter) {
                return () => import(`./subViews/SimpleFooter`);
            } else {
                return "";
            }
        },
    },
    methods: {
        setNoScrollbar() {
            const el = document.querySelector("html");
            el.classList.add("no-scrollbar");
        },
        removeNoScrollbar() {
            const el = document.querySelector("html");
            el.classList.remove("no-scrollbar");
        },
        doDisableMainScroll() {
            if (this.drawer === true) {
                if (this.disableMainScroll === true) {
                    return this.setNoScrollbar();
                }
            } else {
                return this.removeNoScrollbar();
            }
        },
        mouseOver() {
            // console.log('over')
            // this.setNoScrollbar()
        },
        mouseLeave() {
            // console.log('leave')
            // if (this.disableMainScroll === false) {
            //   console.log('remove')
            //   this.removeNoScrollbar()
            // }
        },
    },
    watch: {
        drawer() {
            // this.doDisableMainScroll()
        },
    },
};
</script>

<style lang="scss">

a#navbar-logo {
    height: 12vh;

    img {
        display: block;
        align-self: center;
        margin: 0 auto;
        width: 80%;
        height: auto;
    }

}

#sidebar-drawer {
    .v-navigation-drawer__content {
        max-height: 79%;
    }
}

</style>
