import { treatActionsRoutes } from '../utils'

const defaultState = {
  id: null,
  name: null,
  username: null,
  email: null,
  created: null,
  phone: null,
  company: null,
  tax_payer: null,
  billingday: null,
  refreshToken: null,
  apiKeyList: [],
  notifications: [],
  is_phone_verified: false,
  isAdmin: false,
  isRefreshingToken: false,
  tfa: undefined,
}

const state = { ...defaultState }

const routes = [
  {
    method: "GET",
    state: "*",
    route: "/account/get_user_info",
    action: "doGetUserInfo",
  },
  {
    method: "PATCH",
    route: "/account/{field}",
    action: "updateAccountInfo",
  },
  {
    method: 'DELETE',
    route: '/account',
    action: 'selfDeleteAccount',
  },
  {
    method: "GET",
    state: "notifications",
    route: "/account/notification",
    action: "doGetNotificationList",
  },
  {
    method: "POST",
    route: "/account/phone_verify",
    action: "doPhoneVerify",
  },
  {
    method: "POST",
    route: "/account/phone_check",
    action: "doPhoneCheck",
  },
  {
    method: "POST",
    route: "/account/token",
    action: "doPostToken",
  },
  // API KEY
  {
    method: "POST",
    route: "/account/api_key",
    action: "createApiKey",
  },
  {
    state: "apiKeyList",
    method: "GET",
    route: "/account/api_key",
    action: "getApiKeyList",
  },
  {
    method: "GET",
    route: "/account/api_key/{id}",
    action: "getApiKey",
  },
  {
    method: "PUT",
    route: "/account/api_key/{id}",
    action: "updateApiKey",
  },
  {
    method: "DELETE",
    route: "/account/api_key/{id}",
    action: "deleteApiKey",
  },
]

const getters = {
  GET_USER_INFO: (state) => ({
    ...state,
    phone: state.phone ? state.phone.slice(2) : state.phone,
  }),
  GET_API_KEY_INFO: () => (apiKeyDetail) => ({
    id: apiKeyDetail.id,
    label: apiKeyDetail.label,
    created: Date.parse(apiKeyDetail.created),
  }),
  GET_API_KEY_LIST: (state, getters) => {
    let apiKeyList = state.apiKeyList.map((item) => {
      return getters.GET_API_KEY_INFO(item)
    })
    apiKeyList.sort((a, b) => {
      const dif = a.created - b.created
      if (dif < 0) return 1
      else if (dif > 0) return -1
      else return 0
    })

    return apiKeyList
  },
  GET_IS_REFRESHING_TOKEN: (state) => state.isRefreshingToken
}

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach(key => state[key] = newState[key])
  },
  RESET_STATE(state) {
    Object.keys(state).forEach(key => state[key] = defaultState[key])
  },
  SET_IS_REFRESHING_TOKEN: (state) => state.isRefreshingToken = !state.isRefreshingToken
}

const actions = {
  init() { },
  doResetState({ commit }) {
    commit("RESET_STATE")
  },
}

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = { body: {}, queryString: {}, routeParams: {} }
  ) => treatActionsRoutes(
    item,
    body,
    queryString,
    routeParams,
    commit,
    defaultState,
    "account",
    process.env.VUE_APP_PORTAL_BACKEND_URL
  )
})

export { state, getters, mutations, actions }
