<template>
  <v-row
    justify="center"
    align="start"
    class="fill-height bg-gray"
    no-gutters
  >
    <v-col cols="12">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>
