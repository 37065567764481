import i18n from '../plugins/i18n.plugin'

export default function customExceptions(error) {
    Object.keys(tests).every((test) => {
        const testFunc = tests[test]
        const hasError = testFunc(error)
        if (hasError) {
            error = hasError
            return false
        }
    })
    return error
}

// Add your custom tests here
const tests = {
    test_for_timeout: (err) => {
        if (!err?.message?.includes('timeout')) {
            return null
        }
        return new Error(i18n.tc('error.timeout'))
    },
    test_for_record_not_found: (err) => {
        if (!err?.message?.includes('record not found')) {
            return null
        }
        return new Error(i18n.tc('error.record_not_found'))
    },
    test_for_jwt_is_expired: (err) => {
        if (!err?.message?.includes('JWT is expired')) {
            return null
        }
        return new Error(i18n.tc('error.jwt_is_expired'))
    },
}
