/* eslint-disable */
import { treatActionsRoutes } from '../../utils'

const defaultState = {
    offersList: [],
}

const state = { ...defaultState }

const routes = [
    {
        state: 'offersList',
        method: 'GET',
        route: '/offer?includeInvisible=true',
        action: 'getOffersList',
    },
    {
        method: 'POST',
        route: '/offer',
        action: 'createOffer',
    },
    {
        method: 'PUT',
        route: '/offer/{id}',
        action: 'updateOffer',
    },
    {
        method: 'GET',
        route: '/offer/{id}',
        action: 'getOffer',
    },
    {
        method: 'DELETE',
        route: '/offer/{id}',
        action: 'deleteOffer',
    },
    // PICTURE
    {
        method: 'POST',
        route: '/offer/{id}/picture',
        action: 'createOfferPicture',
    },
    {
        method: 'PUT',
        route: '/offer/{id}/picture/{picture_id}',
        action: 'updateOfferPicture',
    },
    {
        method: 'DELETE',
        route: '/offer/{id}/picture/{picture_id}',
        action: 'deleteOfferPicture',
    },
    // RANGE
    {
        method: 'POST',
        route: '/offer/{id}/range',
        action: 'createOfferRange',
    },
    {
        method: 'PUT',
        route: '/offer/{id}/range/{range_id}',
        action: 'updateOfferRange',
    },
    {
        method: 'DELETE',
        route: '/offer/{id}/range/{range_id}',
        action: 'deleteOfferRange',
    },
    //DISABLE and ENABLE
    {
        method: 'PATCH',
        route: '/offer/{id}/disable',
        action: 'disableOffer',
    },
    {
        method: 'PATCH',
        route: '/offer/{id}/enable',
        action: 'enableOffer',
    },
    // ITEMS
    {
        method: 'POST',
        route: '/offer/{id}/item',
        action: 'createOfferItem',
    },
    {
        method: 'DELETE',
        route: '/offer/{id}/item/{type_id}',
        action: 'deleteOfferItem',
    },
]

const getters = {
    GET_OFFERS_LIST: () => {
        return state.offersList.map((offer) => {
            offer.enabled = false
            return offer
        })
    },
    getOfferInfo: () => (offerDetail) => {
        const sortNumeric = function (a, b) {
            if (a.vlr_inferior < b.vlr_inferior) return -1
            if (a.vlr_inferior > b.vlr_inferior) return 1
            return 0
        }
        return {
            id: offerDetail.id,
            alias: offerDetail.alias,
            name: offerDetail.name,
            description: offerDetail.description,
            logo: offerDetail.logo || '',
            pictures: offerDetail.pictures || [],
            ranges: offerDetail.ranges.sort(sortNumeric) || [],
            items: offerDetail.items,
            summary: offerDetail.summary,
            fixedValue: offerDetail.fixedValue,
            variableValue: offerDetail.variableValue,
            enabled: offerDetail.status,
        }
    },
    getOfferItemsOptions: (state, getters, rootState) => {
        let optionsArray = []
        const occurrenceTypes = rootState.occurrences.occurrenceTypes
        if (!occurrenceTypes) return []
        Object.entries(occurrenceTypes).forEach(([key, value]) => {
            optionsArray.push({ id: key, name: value })
        })
        return optionsArray
    },
    getOfferItems: (state, getters, rootState) => (offerItems) => {
        const occurrenceTypes = rootState.occurrences.occurrenceTypes
        if (!offerItems || !occurrenceTypes) return []
        return offerItems.map((item) => {
            const occurName = occurrenceTypes[item.type_id]
            return {
                id: item.type_id,
                name: occurName || 'type_unknown',
            }
        })
    },
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach((key) => (state[key] = newState[key]))
    },
    RESET_STATE(state) {
        Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
    },
}

const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = {
            body: {},
            queryString: {},
            routeParams: {},
        }
    ) =>
        treatActionsRoutes(
            item,
            body,
            queryString,
            routeParams,
            commit,
            defaultState,
            'adminOffers',
            process.env.VUE_APP_PORTAL_BACKEND_URL
        )
})

export { state, getters, mutations, actions }
