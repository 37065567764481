import { treatActionsRoutes } from "../utils";

const defaultState = {
  groupList: [],
};

const state = { ...defaultState };

const routes = [
  {
    method: "POST",
    route: "/api/group",
    action: "doCreateGroup",
  },
  {
    method: "GET",
    route: "/api/group",
    action: "doGetOwnerGroups",
  },
  {
    method: "GET",
    route: "/api/group/member",
    action: "doGetMemberGroups",
  },
  {
    method: "GET",
    route: "/api/group/{id}",
    action: "doGetGroup",
  },
  {
    method: "PATCH",
    route: "/api/group/{id}",
    action: "doUpdateGroup",
  },
  {
    method: "DELETE",
    route: "/api/group/{id}",
    action: "doRemoveGroup",
  },
  {
    method: "POST",
    route: "/api/group/{groupId}/account",
    action: "doAddUser",
  },
  {
    method: "DELETE",
    route: "/api/group/{groupId}/account/{userId}",
    action: "doRemoveUser",
  },
  {
    method: "POST",
    route: "/api/group/{groupId}/resource/{resourceId}",
    action: "doAddResource",
  },
  {
    method: "DELETE",
    route: "/api/group/{groupId}/resource/{resourceId}",
    action: "doRemoveResource",
  },
];

const getters = {};

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]));
  },
};

const actions = {
  init() {},
  doResetState({ commit }) {
    commit("RESET_STATE");
  },
};

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      "groups",
      process.env.VUE_APP_API_BACKEND_URL
    );
});

export { state, getters, mutations, actions };
