import { Decimal } from 'decimal.js'
import { getTitleForDateRef } from '../../plugins/globals.plugin'
import { treatActionsRoutes } from '../utils'


const defaultState = {
    consumerByDay: [],
    consumer: [],
    signsByDay: null,
    signsByType: null,
    objCount: 0,
    objLength: 0,
    totalSigns: 0,
    invoiceList: [],
    selectedBillingMonth: null,
    selectedMonthBillingDetail: []
}

const state = { ...defaultState }


const routes = [
    {
        state: 'consumerByDay',
        method: 'GET',
        route: '/billing/consumerbyday',
        action: 'doGetConsumerByDay'
    },
    {
        state: 'consumer',
        method: 'GET',
        route: '/billing/consumer',
        action: 'doGetConsumer'
    },
    {
        state: 'signsByDay',
        method: 'GET',
        route: '/billing/signlast',
        action: 'doGetSignsByDay'
    },
    {
        state: 'signsByType',
        method: 'GET',
        route: '/billing/signbytype',
        action: 'doGetSignsByType'
    },
    {
        state: 'objCount',
        method: 'GET',
        route: '/billing/objectcount',
        action: 'doGetObjectsCount',
    },
    {
        state: 'objLength',
        method: 'GET',
        route: '/billing/objectlength',
        action: 'doGetObjectLength',
    },
    {
        method: 'GET',
        route: '/billing/signbytype',
        action: 'doGetTotalSigns',
        stte: 'totalSigns'
    },
    {
        state: 'invoiceList',
        method: 'GET',
        route: '/contract/list-invoice',
        action: 'doGetInvoiceList',
    },
    {
        state: 'selectedMonthBillingDetail',
        method: 'GET',
        route: '/contract/detail-invoice/{dateRef}',
        action: 'doGetMonthBillingDetail',
    },
]

const getters = {
    GET_TOTAL_SUM: () => (invoices) => {
        let sum = new Decimal(0)
        invoices.forEach(invoice => {
            sum = sum.plus(invoice.total)
        })
        return sum
    },
    GET_INVOICE_MONTHS: (state) => {
        let monthArray = []
        state.invoiceList?.forEach(item => {
            if (!monthArray.includes(item.reference)) monthArray.push(item.reference)
        })

        const sortNumericDesc = function (a, b) {
            if (a < b) return 1
            if (a > b) return -1
            return 0
        }
        monthArray.sort(sortNumericDesc)
        let monthOptions = monthArray.map((reference) => {
            return {
                id: reference,
                title: getTitleForDateRef({ dateRef: reference }),
                dateRef: reference,
            }
        })
        return monthOptions
    },
    GET_MONTH_BILLING_DETAIL: (state, getters, rootState) => (selectedMonthBillingDetail) => {
        return selectedMonthBillingDetail.map(invoice => {
            invoice.startDate = Date.parse(invoice.start_date)
            invoice.endDate = Date.parse(invoice.end_date)
            delete invoice.end_date
            delete invoice.start_date

            let contract = { ...invoice.contract }
            contract.start = Date.parse(contract.start)
            contract.end = contract.end ? Date.parse(contract.end) : null

            contract.isActive = contract.end > Date.now() || contract.end === null

            let newItems = invoice.items.map(item => {
                let newItem = {
                    ...item,
                    variableValue: item.variable_value,
                    fixedValue: item.fixed_value,
                    type: rootState.occurrences.occurrenceTypes[item.type]
                }
                delete newItem.fixed_value
                delete newItem.variable_value
                return newItem
            })
            return { ...invoice, items: newItems, contract }
        })
    }
}

const mutations = {
    SET_STATE(state, newState) {
        Object.keys(newState).forEach(key => state[key] = newState[key])
    },
    RESET_STATE(state) {
        Object.keys(state).forEach(key => state[key] = defaultState[key])
    },
}

const actions = {
    doResetState({ commit }) {
        commit('RESET_STATE')
    },
    init({ commit }) {
        commit('RESET_STATE')
    },
}

routes.forEach((item) => {
    actions[item.action] = (
        { commit },
        { body, queryString, routeParams } = { body: {}, queryString: {}, routeParams: {} }
    ) => treatActionsRoutes(
        item,
        body,
        queryString,
        routeParams,
        commit,
        defaultState,
        "billing",
        process.env.VUE_APP_PORTAL_BACKEND_URL
    )
})


export { state, getters, mutations, actions }