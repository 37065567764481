import { treatActionsRoutes } from "../utils";

const defaultState = {
};

const state = { ...defaultState };

const routes = [
  {
    method: "POST",
    route: "/account/tfa",
    action: "doCreateTFA",
  },
  {
    method: "POST",
    route: "/account/tfa/check",
    action: "doCheckTFA",
  },
  {
    method: "DELETE",
    route: "/account/tfa",
    action: "doDeleteTFA",
  },
];

const getters = {};

const mutations = {};

const actions = {
  init() {},
  doResetState({ commit }) {
    commit("RESET_STATE");
  },
};

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      "tfa",
      process.env.VUE_APP_PORTAL_BACKEND_URL
    );
});

export { state, getters, mutations, actions };
