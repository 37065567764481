/* eslint-disable */
import {treatActionsRoutes} from '../utils'
import axios from 'axios'

const defaultState = {
  lastFirmware: {
    version: null,
    summed: 0,
  },
  lastPocketFirmware: {
    version: null,
    summed: 0,
  },
}

const state = {...defaultState}

const routes = []

const getters = {}

function getThroughProx(url, parse) {
  const proxyUrl = `https://zd2xg1w755.execute-api.sa-east-1.amazonaws.com/prod/?url=${url}`
  if (!parse) {
    parse = JSON.parse
  }

  const config = {
    method: 'get',
    url: proxyUrl,
    headers: {
      'X-API-KEY': 'uyXQlcdCEZ1O9RVTum55SaMGcf7UEgth51DbmRg5',
    },
  }

  return axios(config).then((response) => {
    const jsonString = new Buffer(response.data, 'base64').toString('ascii')
    return parse(jsonString)
  })
}

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]))
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]))
  },
}

const actions = {
  doResetState({commit}) {
    commit('RESET_STATE')
  },
  doGetLastFirmwareVersion({commit, dispatch}) {
    dispatch('doGetLastPocketFirmwareVersion')

    return new Promise((resolve, reject) => {
      const RELEASE_INFO_URL = process.env.VUE_APP_VERSION_URL
      const url = `${RELEASE_INFO_URL}.version.json`

      getThroughProx(url)
        .then((response) => {
          const version = response?.version
          const summed = version
            ?.split('.')
            ?.reduce((acc, cur) => parseInt(acc) + parseInt(cur))
          commit('SET_STATE', {
            lastFirmware: {
              version,
              summed,
            },
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  doGetLastPocketFirmwareVersion({commit}) {
    return new Promise((resolve, reject) => {
      const url = process.env.VUE_APP_POCKET_VERSION_URL || 'https://downloads.dinamonetworks.io/bin/firmware/pocket/ver'

      getThroughProx(url)
        .then((response) => {
          const version = response?.version?.split('-')?.slice(2, 3)?.join()
          const summed = version
            ?.split('.')
            ?.reduce((acc, cur) => parseInt(acc) + parseInt(cur))
          commit('SET_STATE', {
            lastPocketFirmware: {
              version,
              summed,
            },
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  doGetReleaseNotes({commit}) {
    return new Promise((resolve, reject) => {
      const RELEASE_INFO_URL = process.env.VUE_APP_VERSION_URL
      const url = `${RELEASE_INFO_URL}.release_notes.md`

      getThroughProx(url, (r) => r)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

routes.forEach((item) => {
  actions[item.action] = (
    {commit},
    {body, queryString, routeParams} = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      'release',
      process.env.VUE_APP_PORTAL_BACKEND_URL
    )
})

export {state, getters, mutations, actions}
