import { treatActionsRoutes } from "../utils";

const defaultState = {};

const state = { ...defaultState };

const routes = [
  {
    method: "POST",
    route: "/api/alias",
    action: "doCreateAlias",
  },
  {
    method: "PUT",
    route: "/api/alias/{refId}/{searchValue}",
    action: "doUpdateAlias",
  },
  {
    method: "DELETE",
    route: "/api/alias/{refId}/{searchValue}",
    action: "doDeleteAlias",
  },
];

const getters = {};

const mutations = {
  SET_STATE(state, newState) {
    Object.keys(newState).forEach((key) => (state[key] = newState[key]));
  },
  RESET_STATE(state) {
    Object.keys(state).forEach((key) => (state[key] = defaultState[key]));
  },
};

const actions = {
  init() {},
  doResetState({ commit }) {
    commit("RESET_STATE");
  },
};

routes.forEach((item) => {
  actions[item.action] = (
    { commit },
    { body, queryString, routeParams } = {
      body: {},
      queryString: {},
      routeParams: {},
    }
  ) =>
    treatActionsRoutes(
      item,
      body,
      queryString,
      routeParams,
      commit,
      defaultState,
      "alias",
      process.env.VUE_APP_API_BACKEND_URL
    );
});

export { state, getters, mutations, actions };
