import Vue from "vue"

const files = require.context('.', false, /filter\.js$/)


files.keys().forEach(key => {
    const filters = files(key)
    Object.keys(filters).forEach(filterName => {
        Vue.filter(filterName, filters[filterName])
    })
})