export function phoneFormat(value) {
    if (!value) {
        return ''
    }
    // TODO: check if number has 55 prefix and remove it. Refactor this code if add more countries
    if (value.startsWith('55')) {
        value = value.substring(2)
    }
    return value?.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
}
